import { useNavigate } from 'react-router-dom';
import classes from './Taxrate.module.css';
import NewTaxrateForm from '../components/Bookings/NewTaxrateForm';
import PostAPI from '../api/PostAPI';

export default function NewTaxratePage() {
    const navigate = useNavigate();
    const PAGE_URL = 'taxrate';
    async function storeNewTaxrateHandler(newData) {
        await PostAPI(PAGE_URL, newData)
            .then((result) => {
                navigate("/taxrate");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.tax}>
            <h1>Create New Taxrate</h1>
            <NewTaxrateForm onStoreNewTaxrate={storeNewTaxrateHandler} />
        </div>
    );
}