import { useRef, useState, useEffect } from "react";
import Card from "../ui/Card";
import classes from "./NewBookingForm.module.css";
import GetAPI from "../../api/GetAPI";
import YearSelect from '../../helpers/YearSelector';

export default function NewBookingForm(props) {
    const dateInputRef = useRef();
    const [bookingNumber, setBookingNumber] = useState(0);
    const [selectedRelation, setRelation] = useState();
    const [loadedRelations, setLoadedRelations] = useState([]);
    const [selectedProduct, setProduct] = useState();
    const [loadedProduct, setLoadedProduct] = useState([]);
    const [bookingPrice, setBookingPrice] = useState('');
    const [selectedProductTaxRate, setProductTaxRate] = useState('');
    const [taxRateId, setTaxRateId] = useState();
    const [bookingTaxAmount, setBookingTaxAmount] = useState('');
    const [bookingTotal, setBookingTotal] = useState('');
    const [selectedFirstChoice, setFirstChoice] = useState();
    const [selectedFirstPart, setFirstPart] = useState();
    const [loadedAsset, setLoadedAsset] = useState([]);
    const [selectedDoFirstPart, setDoFirstPart] = useState();
    const [selectedSecondChoice, setSecondChoice] = useState();
    const [selectedSecondPart, setSecondPart] = useState();
    const [selectedDoSecondPart, setDoSecondPart] = useState();
    const [selectedDoTax, setDoTax] = useState();
    const [selectedReference, setReference] = useState();
    const referenceInputRef = useRef();
    const [selectedYear, setSelectedYear] = useState(YearSelect());

    // async function getBookingNumber() {
    //     await GetAPI('booking/lastnumber/' + selectedYear.toString().slice(-2))
    //         .then((data) => {
    //             if (data > 0) {
    //                 setBookingNumber(data + 1);
    //             } else {
    //                 setBookingNumber(Number(selectedYear.toString().slice(-2) + '00001'));
    //             }
    //         })
    // }

    async function getRelation() {
        await GetAPI('relation')
            .then((data) => {
                if (data.length) {
                    setLoadedRelations(data);
                } else {
                    setLoadedRelations([]);
                }
            })
    }

    async function getAsset() {
        await GetAPI('asset')
            .then((data) => {
                setLoadedAsset(data);
            })
    }

    useEffect(() => {
        getRelation()
        getAsset()
    }, [])

    useEffect(() => {
        GetAPI('booking/lastnumber/' + selectedYear.toString().slice(-2))
            .then((data) => {
                if (data > 0) {
                    setBookingNumber(data + 1);
                } else {
                    setBookingNumber(Number(selectedYear.toString().slice(-2) + '00001'));
                }
            })
    }, [selectedYear])

    async function getLoadedProduct(selectedRelation) {
        if (selectedRelation) {
            await GetAPI('product/relation/' + selectedRelation)
                .then((data) => {
                    if (data.length) {
                        setLoadedProduct(data);
                        if (data.length === 1) {
                            console.log("Products: " + JSON.stringify(data));
                            setProduct(data[0]._id);
                            setFirstChoice(data[0].firstChoice);
                            setFirstPart(data[0].firstPart);
                            setDoFirstPart(data[0].doFirstPart);
                            setSecondChoice(data[0].secondChoice);
                            setSecondPart(data[0].secondPart);
                            setDoSecondPart(data[0].doSecondPart);
                            setDoTax(data[0].doTax);
                            setReference(data[0].reference);
                        }
                    } else {
                        setLoadedProduct([]);
                    }
                })
        }
    }

    async function getSelectedProduct(selectedProduct) {
        if (selectedProduct) {
            await GetAPI('product/' + selectedProduct)
                .then((data) => {
                    if (data) {
                        setTaxRateId(data.taxRate._id);
                        setProductTaxRate(data.taxRate.tax);
                        setFirstChoice(data.firstChoice);
                        setFirstPart(data.firstPart);
                        setDoFirstPart(data.doFirstPart);
                        setSecondChoice(data.secondChoice);
                        setSecondPart(data.secondPart);
                        setDoSecondPart(data.doSecondPart);
                        setDoTax(data.doTax);
                        setReference(data.reference);
                    } else {
                        setProductTaxRate('')
                    }
                })
        }
    }

    useEffect(() => {
        getLoadedProduct(selectedRelation)
        getSelectedProduct(selectedProduct)
    }, [selectedProduct, selectedRelation])

    function submitHandler(event) {
        event.preventDefault();
        const enteredDate = dateInputRef.current.value;
        const enteredReference = referenceInputRef.current.value;
        const bodyData = {
            bookingNumber: bookingNumber,
            bookingDate: enteredDate,
            relation: selectedRelation,
            product: selectedProduct,
            price: bookingPrice,
            taxRateId: taxRateId,
            taxAmount: bookingTaxAmount,
            total: bookingTotal,
            firstPart: selectedFirstPart,
            doFirstPart: selectedDoFirstPart,
            secondPart: selectedSecondPart,
            doSecondPart: selectedDoSecondPart,
            doTax: selectedDoTax,
            reference: enteredReference
        };
        props.onStoreNew(bodyData);
    }

    function previousYearHandler(event) {
        event.preventDefault();
        setSelectedYear(selectedYear - 1)
    }

    function nextYearHandler(event) {
        event.preventDefault();
        setSelectedYear(selectedYear + 1)
    }

    return <Card className={classes.card}>
        <button className={classes.navBtn} onClick={previousYearHandler}>Previous Year</button>
        <h1>Boekjaar {selectedYear}</h1>
        <button className={classes.navBtn} onClick={nextYearHandler}>Next Year</button>
        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>

                <label htmlFor='title'>BookingNumber</label>
                <input type='number' required id='bookingNumber' value={bookingNumber} readOnly={true} />
            </div>

            <div>
                <label htmlFor='title'>Date</label>
                <input type='date' required id='date' ref={dateInputRef} />
            </div>

            <div>
                <label htmlFor='title'>Relation</label>
                <select value={selectedRelation} required id='relation' onChange={e => setRelation(e.target.value)}>
                    <option></option>
                    {loadedRelations.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Product</label>
                <select value={selectedProduct} required id='product' onChange={e => {
                    setProduct(e.target.value)
                }}>
                    <option></option>
                    {loadedProduct.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Price</label>
                <input type='number' value={bookingPrice} required id='bookingPrice' onChange={e => {
                    setBookingPrice(e.target.value);
                    setBookingTaxAmount(+(((selectedProductTaxRate / 100)) * (e.target.value)).toFixed(2));
                    setBookingTotal(+((1 + (selectedProductTaxRate / 100)) * (e.target.value)).toFixed(2));
                }} />
            </div>

            <div>
                <label htmlFor='title'>TaxRate</label>
                <input type='text' value={selectedProductTaxRate} required id='taxRate' readOnly={true} />
            </div>

            <div>
                <label htmlFor='title'>TaxAmount</label>
                <input type='number' value={bookingTaxAmount} required id='taxAmount' readOnly={true} />
            </div>

            <div>
                <label htmlFor='title'>Total</label>
                <input type='number' value={bookingTotal} required id='bookingTotal' readOnly={true}
                />
            </div>

            <div>
                <label htmlFor='title'>FirstChoice</label>
                <select value={selectedFirstChoice} required id='product' onChange={e => setFirstChoice(e.target.value)}>
                    <option value={selectedFirstChoice} defaultValue={selectedFirstChoice}>{selectedFirstChoice}</option>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>FirstPart</label>
                <select value={selectedFirstPart} required id='firstPart' onChange={e => setFirstPart(e.target.value)}>
                    <option></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedFirstChoice) {
                            return (
                                <option key={item._id} value={item._id}> {item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoFirstPart} required id='doFirst' onChange={e => setDoFirstPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="add" value="add">Add</option>
                    <option key="substract" value="substract">Substract</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondChoice</label>
                <select value={selectedSecondChoice} required id='product' onChange={e => setSecondChoice(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondPart</label>
                <select value={selectedSecondPart} required id='firstPart' onChange={e => setSecondPart(e.target.value)}>
                    <option></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedSecondChoice) {
                            return (
                                <option key={item._id} value={item._id}> {item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoSecondPart} required id='doSecond' onChange={e => setDoSecondPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="add" value="add">Add</option>
                    <option key="substract" value="substract">Substract</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>TaxAction</label>
                <select value={selectedDoTax} id='product' onChange={e => setDoTax(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="pay" value="pay">Pay</option>
                    <option key="recieve" value="recieve">Recieve</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>Reference</label>
                <input type='text' required id='reference' defaultValue={selectedReference} ref={referenceInputRef} />
            </div>
            <div className={classes.action}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}