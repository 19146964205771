import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Product.module.css';
import BookItem from '../components/Bookings/BookItem';
import ExternalDeleteHandler from '../components/DeleteHandler';
import GetAPI from '../api/GetAPI';

export default function Producten() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    // const [loadedAssetId, setLoadedAssetId] = useState();
    // const [loadedAsset, setLoadedAsset] = useState([]);
    // const [firstPartName, setFirstPartName] = useState();
    const PAGE_URL = 'product';

    async function getData() {
        setIsLoading(true);
        await GetAPI(PAGE_URL)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [PAGE_URL])

    if (isLoading) {
        return (
            <section>
                <p>{PAGE_URL}</p>
                <p>Loading....</p>
            </section>
        );
    }

    function newHandler(event) {
        event.preventDefault();
        navigate("/newproduct");
    }

    function changeHandler(event) {
        event.preventDefault();
        console.log(event.target.value);
        const pLoad = JSON.parse(event.target.value)
        navigate("/changeproduct/" + pLoad.id);
    }

    function passDeleteHandler(event) {
        ExternalDeleteHandler(event)
            .then((result) => {
                console.log("output: " + result);
                navigate(0);
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.product}>
            <h1>All Products</h1>
            <ul>
                <div className={classes.newBtnDiv} >
                    <button className={classes.navBtn} onClick={newHandler}>Create New Product</button>
                </div>
                {/* <BookItem> */}
                    <div className={classes.titlerow}>
                        <div className={classes.col4}>Product</div>
                        <div className={classes.col4}>Relation</div>
                        <div className={classes.col2}>ArticleNumber</div>
                        <div className={classes.col2}>Taxrate</div>
                        <div className={classes.col2}>FirstChoice</div>
                        <div className={classes.col2}>FirstPart</div>
                        <div className={classes.col2}>DoFirst</div>
                        <div className={classes.col2}>secondChoice</div>
                        <div className={classes.col2}>secondPart</div>
                        <div className={classes.col2}>doSecond</div>
                        <div className={classes.col2}>doTax</div>
                        <div className={classes.actions}>
                        </div>
                    </div>
                {/* </BookItem> */}
                {loadedData.map((item) => {
                    return (
                        <BookItem key={item._id}>
                            <div className={classes.bookingtable} key={item._id} >
                                <div className={classes.col4}>{item.name}</div>
                                <div className={classes.col4}>{item.relation.name}</div>
                                <div className={classes.col2}>{item.articleNumber}</div>
                                <div className={classes.col2}>{item.taxRate.name}</div>
                                <div className={classes.col2}>{item.firstChoice}</div>
                                <div className={classes.col2}>{item.firstPart}</div>
                                <div className={classes.col2}>{item.doFirstPart}</div>
                                <div className={classes.actions}>
                                    <button className={classes.btn} onClick={changeHandler} value={JSON.stringify({
                                        id: item._id,
                                        name: item.name,
                                        relation: item.relation.name,
                                        articleNumber: item.articleNumber,
                                        taxRate: item.taxRate.name,
                                        firstChoice: item.firstChoice,
                                        firstPart: item.firstPart,
                                        doFirstPart: item.doFirstPart,
                                        secondChoice: item.secondChoice,
                                        secondPart: item.secondPart,
                                        doSecondPart: item.doSecondPart,
                                        doTax: item.doTax
                                    })}>Change</button>
                                    <button className={classes.btn} onClick={passDeleteHandler} value={JSON.stringify({ id: item._id, path: "product" })}>Delete</button>
                                </div>
                            </div>
                        </BookItem>
                    )
                })}
            </ul>
        </div>
    )
}