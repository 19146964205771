import { Route, Routes } from "react-router-dom";
import React, { useState } from "react";
import Layout from './components/layout/Layout';
import HomePage from './pages/Home';
import Boekingen from './pages/Booking';
import NewBookingPage from "./pages/NewBooking";
import Balans from './pages/Balans';
import Resultatenrekening from './pages/Resultatenrekening';
import TaxratePage from "./pages/Taxrate";
import NewTaxratePage from "./pages/NewTaxrate";
import ChangeTaxratePage from "./pages/ChangeTaxrate";
import Relaties from "./pages/Relation";
import NewRelationPage from "./pages/NewRelation";
import ChangeRelationPage from "./pages/ChangeRelation";
import Producten from "./pages/Product";
import NewProductPage from "./pages/NewProduct";
import ChangeProductPage from "./pages/ChangeProduct";
import BTW from "./pages/Tax";
import NewCostAndRevenuePage from "./pages/NewCostAndRevenue";
import ChangeCostAndRevenuePage from "./pages/ChangeCostAndRevenue";
import AssetPage from "./pages/Asset";
import NewAssetPage from "./pages/NewAsset";
import ChangeAssetPage from "./pages/ChangeAsset";
import ChangeBookingPage from "./pages/ChangeBooking";
import BookingTable from './pages/BookingTable';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import axios from "./api/Axios";

export function LoadToken() {
  if (sessionStorage) {
    if (JSON.stringify(sessionStorage).includes("AccessToken")) {
      const axiosToken = JSON.stringify(sessionStorage).split("AccessToken")[1].split("secret")[1].split("\\")[2].split('"')[1]
      axios.interceptors.request.use((config) => {
        if (axiosToken) {
          config.headers.Authorization = `Bearer ${axiosToken}`;
         }
        return config
      }, error => {
        return Promise.reject(error);
      }
      )
    }
  }
  return (
    <>
      <h5 className="card-title-2">You're good to go.</h5>
    </>
  );
}

function ProfileContent() {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState(null);
  const name = accounts[0] && accounts[0].name;
  function RequestAccessToken() {
    const request = {
      ...loginRequest,
      account: accounts[0]
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance.acquireTokenSilent(request).then((response) => {
      setAccessToken(response.accessToken);
      axios.interceptors.request.use((config) => {
        if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
        return config
      }, error => {
        return Promise.reject(error);
      }
      )
    }).catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        setAccessToken(response.accessToken);
        axios.interceptors.request.use((config) => {
          if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`;
          return config
        }, error => {
          return Promise.reject(error);
        }
        )
      });
    });
  }
  return (
    <>
      <h5 className="card-title-1">Welcome {name}. </h5>
      {accessToken ?
        <div>
          <p>Access Token Acquired!</p>
          <br></br>
          <p>{accessToken}</p>
          <br></br>
          <p></p>
        </div>
        :
        <button className="tokenbutton" onClick={RequestAccessToken}>Request Access Token</button>
      }
    </>
  );
};

function App() {
  return (
    <div>
      <AuthenticatedTemplate>
        <Layout>
          <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/booking' caseSensitive={false} element={<Boekingen />} />
            <Route path='/product' caseSensitive={false} element={<Producten />} />
            <Route path='/relation' caseSensitive={false} element={<Relaties />} />
            <Route path='/balance' caseSensitive={false} element={<Balans />} />
            <Route path='/profitandloss' caseSensitive={false} element={<Resultatenrekening />} />
            <Route path='/tax' caseSensitive={false} element={<BTW />} />
            <Route path='/asset' caseSensitive={false} element={<AssetPage />} />
            <Route path='/taxrate' caseSensitive={false} element={<TaxratePage />} />
            <Route path='/bookingtable' caseSensitive={false} element={<BookingTable />} />
          </Routes>
        </Layout>
        <Routes>
          <Route path='/' element={<HomePage />} />
          <Route path='/booking' caseSensitive={false} element={<Boekingen />} />
          <Route path='/newbooking' caseSensitive={false} element={<NewBookingPage />} />
          <Route path='/balance' caseSensitive={false} element={<Balans />} />
          <Route path='/profitandloss' caseSensitive={false} element={<Resultatenrekening />} />
          <Route path='/taxrate' caseSensitive={false} element={<TaxratePage />} />
          <Route path='/newtaxrate' caseSensitive={false} element={<NewTaxratePage />} />
          <Route path='/changetaxrate/:taxId' caseSensitive={false} element={<ChangeTaxratePage />} />
          <Route path='/tax' caseSensitive={false} element={<BTW />} />
          <Route path='/newcostandrevenue' caseSensitive={false} element={<NewCostAndRevenuePage />} />
          <Route path='/costandrevenue/:id' caseSensitive={false} element={<ChangeCostAndRevenuePage />} />
          <Route path='/asset' caseSensitive={false} element={<AssetPage />} />
          <Route path='/newasset' caseSensitive={false} element={<NewAssetPage />} />
          <Route path='/asset/:id' caseSensitive={false} element={<ChangeAssetPage />} />
          <Route path='/relation' caseSensitive={false} element={<Relaties />} />
          <Route path='/newrelation' caseSensitive={false} element={<NewRelationPage />} />
          <Route path='/changerelation/:relationId' caseSensitive={false} element={<ChangeRelationPage />} />
          <Route path='/product' caseSensitive={false} element={<Producten />} />
          <Route path='/newproduct' caseSensitive={false} element={<NewProductPage />} />
          <Route path='/changeproduct/:productId' caseSensitive={false} element={<ChangeProductPage />} />
          <Route path='/changebooking/:bookingId' caseSensitive={false} element={<ChangeBookingPage />} />
          <Route path='/bookingtable' caseSensitive={false} element={<BookingTable />} />
        </Routes>
        <br></br>
        <ProfileContent />
        <LoadToken />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Layout>
          <p>You are not signed in! Please sign in.</p>
        </Layout>
      </UnauthenticatedTemplate>
    </div>
  );
}

export default App;