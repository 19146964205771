import { useRef, useState, useEffect } from "react";
import Card from "../ui/Card";
import classes from "./NewProductForm.module.css";
import GetAPI from "../../api/GetAPI";

export default function ChangeProductForm(props) {
    console.log("ChangeProductFormprops: " + JSON.stringify(props));
    const nameInputRef = useRef();
    const articleNumberInputRef = useRef();
    const [selectedRelation, setRelation] = useState(props.relationId);
    const [loadedRelations, setLoadedRelations] = useState([]);
    const [selectedTax, setTax] = useState(props.taxId);
    const [loadedTax, setLoadedTax] = useState([]);
    const [selectedFirstChoice, setFirstChoice] = useState(props.firstChoice);
    const [selectedFirstPart, setFirstPart] = useState(props.firstPart);
    const [loadedAsset, setLoadedAsset] = useState([]);
    const [loadedFirstAsset, setLoadedFirstAsset] = useState(props.firstPart);
    const [loadedSecondAsset, setLoadedSecondAsset] = useState(props.secondPart);
    const [selectedDoFirstPart, setDoFirstPart] = useState(props.doFirstPart);
    const [selectedSecondChoice, setSecondChoice] = useState(props.secondChoice);
    const [selectedSecondPart, setSecondPart] = useState(props.secondPart);
    const [selectedDoSecondPart, setDoSecondPart] = useState(props.doSecondPart);
    const [selectedDoTax, setDoTax] = useState(props.doTax);
    const referenceInputRef = useRef(props.reference);

    async function getRelation() {
        await GetAPI('relation')
            .then((data) => {
                if (data.length) {
                    setLoadedRelations(data);
                }
            })
    }

    async function getTaxRate() {
        await GetAPI('taxrate')
            .then((data) => {
                setLoadedTax(data);
            })
    }

    async function getAsset() {
        await GetAPI('asset')
            .then((data) => {
                setLoadedAsset(data);
            })
    }

    useEffect(() => {
        getRelation()
        getTaxRate()
        getAsset()
    }, [])

    async function getFirstPart(loadedData) {
        if (loadedData?.firstPart) {
            console.log("getFirstPartId: " + JSON.stringify(loadedData.firstPart));
            await GetAPI('asset/' + loadedData.firstPart)
                .then((data) => {
                    console.log("getFirstPartLoadedData: " + JSON.stringify(data));
                    setLoadedFirstAsset(data);
                })
        }
    }

    async function getSecondPart(loadedData) {
        if (loadedData?.secondPart) {
            console.log("getSecondPartId: " + JSON.stringify(loadedData.secondPart));
            await GetAPI('asset/' + loadedData.secondPart)
                .then((data) => {
                    console.log("getSecondPartLoadedData: " + JSON.stringify(data));
                    setLoadedSecondAsset(data);
                })
        }
    }

    useEffect(() => {
        getFirstPart(props);
        getSecondPart(props);
    }, [props])

    // useEffect(() => {
    //     setFirstPart("");
    //     setDoFirstPart("");
    // }, [selectedFirstChoice])

    // useEffect(() => {
    //     setSecondPart("");
    //     setDoSecondPart("");
    // }, [selectedSecondChoice])

    function submitHandler(event) {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;
        const enteredArticleNumber = articleNumberInputRef.current.value;
        const enteredReference = referenceInputRef.current.value;
        let newRelation = "";
        if (selectedRelation) {
            newRelation = selectedRelation;
        } else {
            newRelation = props.relationId;
        }
        let newTaxRateId = "";
        if (selectedTax) {
            newTaxRateId = selectedTax;
        } else {
            newTaxRateId = props.taxId;
        }

        let newFirstChoice = "";
        if (selectedFirstChoice) {
            newFirstChoice = selectedFirstChoice;
        } else {
            newFirstChoice = props.firstChoice;
        }
        let newFirstPart = "";
        if (selectedFirstPart) {
            newFirstPart = selectedFirstPart;
        } else {
            newFirstPart = props.firstPart;
        }
        let newDoFirstPart = "";
        if (selectedDoFirstPart) {
            newDoFirstPart = selectedDoFirstPart;
        } else {
            newDoFirstPart = props.doFirstPart;
        }
        let newSecondChoice = "";
        if (selectedSecondChoice) {
            newSecondChoice = selectedSecondChoice;
        } else {
            newSecondChoice = props.secondChoice;
        }
        let newSecondPart = "";
        if (selectedSecondPart) {
            newSecondPart = selectedSecondPart;
        } else {
            newSecondPart = props.secondPart;
        }
        let newDoSecondPart = "";
        if (selectedDoSecondPart) {
            newDoSecondPart = selectedDoSecondPart;
        } else {
            newDoSecondPart = props.doSecondPart;
        }
        let newDoTax = "";
        if (selectedDoTax !== props.doTax) {
            newDoTax = selectedDoTax;
        } else {
            newDoTax = props.doTax;
        }
        const bodyData = {
            name: enteredName,
            relation: newRelation,
            articleNumber: enteredArticleNumber,
            taxRate: newTaxRateId,
            firstChoice: newFirstChoice,
            firstPart: newFirstPart,
            doFirstPart: newDoFirstPart,
            secondChoice: newSecondChoice,
            secondPart: newSecondPart,
            doSecondPart: newDoSecondPart,
            doTax: newDoTax,
            reference: enteredReference
        };
        props.onStoreChangedData(bodyData);
    }

    return <Card className={classes.card}>
        <form className={classes.card} onSubmit={submitHandler}>
            <div className={classes.control}>

                <label htmlFor='title'>Name</label>
                <input type='text' required id='name' defaultValue={props.name} ref={nameInputRef} />
            </div>
            <div>
                <label htmlFor='title'>Relation</label>
                <select value={selectedRelation} required id='relation' onChange={e => setRelation(e.target.value)}>

                    {!selectedRelation &&
                        <option key={props.relationId}
                            defaultValue={props.relationId}>{props.relationName}</option>
                    }
                    {loadedRelations.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className={classes.control}>
                <label htmlFor='title'>ArticleNumber</label>
                <input type='text' id='articleNumber' defaultValue={props.articleNumber} ref={articleNumberInputRef} />
            </div>
            <div>
                <label htmlFor='title'>taxRate</label>
                <select value={selectedTax} required id='taxRate' onChange={e => setTax(e.target.value)}>
                    {loadedTax.map((item) => {
                        if (item._id === selectedTax._id) {
                            return (
                                <option key={item._id} defaultValue={item._id}> {item.name}</option>
                            )
                        } else {
                            return (
                                <option key={item._id} value={item._id}> {item.name}</option>
                            )
                        }
                    })}
                </select>
            </div>
            <div>
                <label htmlFor='title'>FirstChoice</label>
                <select value={selectedFirstChoice} required id='firstChoice' onChange={e => {
                    setFirstChoice(e.target.value)
                    setFirstPart("")
                    setDoFirstPart("")
                }
                }>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>FirstPart</label>
                <select value={selectedFirstPart} required id='firstPart' onChange={e => setFirstPart(e.target.value)}>
                <option key="none" value=""></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedFirstChoice) {
                            return (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoFirstPart} required id='doFirst' onChange={e => setDoFirstPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="add" value="add">Add</option>
                    <option key="substract" value="substract">Substract</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondChoice</label>
                <select value={selectedSecondChoice} required id='secondChoice' onChange={e => {
                    setSecondChoice(e.target.value)
                    setSecondPart("");
                    setDoSecondPart("");
                }
                }>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondPart</label>
                <select value={selectedSecondPart} required id='secondPart' onChange={e => setSecondPart(e.target.value)}>
                <option key="none" value=""></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedSecondChoice) {
                            return (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoSecondPart}
                    required id='doSecond' onChange={e => setDoSecondPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="add" value="add">Add</option>
                    <option key="substract" value="substract">Substract</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>TaxAction</label>
                <select value={selectedDoTax} id='product' onChange={e => setDoTax(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="pay" value="pay">Pay</option>
                    <option key="recieve" value="recieve">Recieve</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>Reference</label>
                <input type='text' id='reference' defaultValue={props.reference} ref={referenceInputRef} />
            </div>
            <div className={classes.action}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}

