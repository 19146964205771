import { useNavigate } from 'react-router-dom';
import classes from './Taxrate.module.css';
import NewAssetForm from '../components/Bookings/NewAssetForm';
import PostAPI from '../api/PostAPI';

export default function NewAssetPage() {
    const navigate = useNavigate();
    const PAGE_URL = 'asset';

    async function storeNewHandler(newData) {
        await PostAPI(PAGE_URL, newData)
            .then(() => {
                navigate("/asset");
            })
            .catch((err) => {
                console.log(err)
            });
    }
    return (
        <div className={classes.tax}>
            <h1>Create New Asset</h1>
            <NewAssetForm onStoreNew={storeNewHandler} />
        </div>
    );
}