import { useState, useEffect } from 'react';
import BookItem from '../components/Bookings/BookItem';
import classes from './Balance.module.css';
import GetAPI from '../api/GetAPI';
import YearSelect from '../helpers/YearSelector';

export default function Resultatenrekening() {
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    let totalDebt = 0;
    let totalCredit = 0;
    let cssClass = "";
    // const PAGE_URL = 'profitandloss';
    const [selectedYear, setSelectedYear] = useState(YearSelect());

    // async function getData() {
    //     setIsLoading(true);
    //     await GetAPI(PAGE_URL)
    //         .then((data) => {
    //             console.log("profitandlossData: " + JSON.stringify(data));
    //             setLoadedData(data);
    //             setIsLoading(false)
    //         })
    // }

    useEffect(() => {
        async function getData() {
            setIsLoading(true);
            await GetAPI('profitandloss/' + selectedYear)
                .then((data) => {
                    console.log("profitandlossData: " + JSON.stringify(data));
                    setLoadedData(data);
                    setIsLoading(false)
                })
        }
        getData()
    }, [selectedYear])

    function previousYearHandler(event) {
        event.preventDefault();
        setSelectedYear(selectedYear-1)
        // navigate("/newbooking");
    }
    
    function nextYearHandler(event) {
        event.preventDefault();
        setSelectedYear(selectedYear+1)
        // navigate("/newbooking");
    }

    if (isLoading) {
        return (
            <section>
                <p>profitandloss </p>
                <p>Loading....</p>
            </section>
        );
    }
    return (
        <div className={classes.base}>
            <div className={classes.navRow}>
            <button className={classes.navBtn} onClick={previousYearHandler}>Previous Year</button>
            <h1>Resultatenrekening {selectedYear}</h1>
            <button className={classes.navBtn} onClick={nextYearHandler}>Next Year</button>
                </div>
            <ul>
                <div className={classes.bookingtable} >
                    <BookItem key='titleRow' className={classes.card}>
                        <div className={classes.titlerow}>
                            <div className={classes.title}>Debet</div>
                            <div className={classes.title}>Credit</div>
                        </div>
                        <div className={classes.balanceTable}>
                            <div className={classes.balanceTableDebt}>
                                {loadedData.map((item) => {
                                    if ((item?.side === "Debet") && (item?.balance !== 0)) {
                                        totalDebt += item.balance;
                                        cssClass = classes.debt;
                                        return (
                                            <div className={cssClass} key={item._id}>
                                                <div className={classes.title}>{item.name}</div>
                                                <div className={classes.value}>{(+item.balance.toFixed(2))}</div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (null)
                                    }
                                })}
                            </div>
                            <div className={classes.balanceTableCredit}>
                                {loadedData.map((item) => {
                                    if ((item?.side === "Credit") && (item?.balance !== 0)) {
                                        totalCredit += item.balance;
                                        cssClass = classes.credit;
                                        return (
                                            <div className={cssClass} key={item._id}>
                                                <div className={classes.title}>{item.name}</div>
                                                <div className={classes.value}>{item.balance}</div>
                                            </div>
                                        )
                                    }
                                    else {
                                        return (null)
                                    }
                                })}
                            </div>
                        </div>

                        <div className={classes.balanceTable}>
                            <div className={classes.balanceTableDebt}>
                                <div className={classes.debt} key="winst">
                                    <div className={classes.boldTitle}>Winst</div>
                                    <div className={classes.boldValue}>{(+(totalCredit - totalDebt).toFixed(2))}</div>
                                </div>
                            </div>
                        </div>

                        <div className={classes.balanceTable}>
                            <div className={classes.balanceTableDebt}>
                                <div className={classes.debt} key="totalDebtId">
                                    <div className={classes.boldTitle}>Totaal</div>
                                    <div className={classes.boldValue}>{totalCredit}</div>
                                </div>
                            </div>
                            <div className={classes.balanceTableCredit}>
                                <div className={classes.credit} key="totalCreditId">
                                    <div className={classes.boldTitle}>Totaal</div>
                                    <div className={classes.boldValue}>{totalCredit}</div>
                                </div>
                            </div>
                        </div>

                    </BookItem>
                </div>
            </ul>
        </div>

    )
}