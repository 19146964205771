import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './Relation.module.css';
import ChangeRelationForm from '../components/Bookings/ChangeRelationForm';
import Card from '../components/ui/Card';
import DisplayField from '../components/ui/DisplayField';
import GetAPI from '../api/GetAPI';
import PutAPI from '../api/PutAPI';

export default function ChangeRelationPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'relation';
    const params = useParams();
    const targetId = params.relationId;
    console.log("relationId: ", params)

    async function getData(targetId) {
        setIsLoading(true);
        await GetAPI(PAGE_URL + '/' + targetId)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData(targetId)
    }, [PAGE_URL, targetId])

    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }


    async function storeChangedRelationHandler(bodyData) {
        const fullNewData = {
            id: targetId,
            name: bodyData.name,
            address: bodyData.address
        }
        console.log("sending: " + JSON.stringify(fullNewData));
        await PutAPI(PAGE_URL, fullNewData)
            .then((result) => {
                navigate("/relation");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.relation}>
            <h1>Change Relation</h1>
            <Card className={classes.card}>
                <h1>Current values</h1>
                <DisplayField type='text' label='Id' value={loadedData._id} readOnly={true} />
                <DisplayField type='text' label='Name' value={loadedData.name} readOnly={true} />
                <DisplayField type='text' label='Address' value={loadedData.address} readOnly={true} />
            </Card>
            <ChangeRelationForm onStoreChangedRelation={storeChangedRelationHandler} relationName={loadedData.name} relationAddress={loadedData.address} />
        </div>
    );
}