import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './StandardPage.module.css';
import BookItem from '../components/Bookings/BookItem';
import ExternalDeleteHandler from '../components/DeleteHandler';
import GetAPI from '../api/GetAPI';
import Layout from '../components/layout/Layout';

export default function AssetPage() {
    console.log("Layout props: " + JSON.stringify(Layout.props))
    // Layout.toggleNav();
    // if(Layout.navMenu){Layout.toggleNav()};
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'asset';

    async function getData() {
        setIsLoading(true);
        await GetAPI(PAGE_URL)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [PAGE_URL])

    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }

    function newHandler(event) {
        event.preventDefault();
        navigate("/newasset");
    }

    function changeHandler(event) {
        event.preventDefault();
        const pLoad = JSON.parse(event.target.value)
        navigate("/asset/" + pLoad.id);
    }

    function passDeleteHandler(event) {
        ExternalDeleteHandler(event)
            .then((result) => {
                navigate(0);
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.sPage}>
            <h1>All Assets</h1>
            <ul>
                <div className={classes.newBtnDiv} >
                    <button className={classes.navBtn} onClick={newHandler}>Create New Asset</button>
                </div>
                {loadedData.map((item) => {
                    return (
                        <BookItem className={classes.card} key={item._id}>
                            <div className={classes.bookingtable} key={item._id} >
                                <div className={classes.col6}>{item.name}</div>
                                <div className={classes.col5}>{item.type}</div>
                                <div className={classes.col5}>{item.side}</div>
                                <div className={classes.col5}>{item.category}</div>
                                <div className={classes.actions}>
                                    <button className={classes.btn} onClick={changeHandler} value={JSON.stringify({ id: item._id, name: item.name, type: item.type, side: item.side, category: item.category })}>Change</button>
                                    <button className={classes.btn} onClick={passDeleteHandler} value={JSON.stringify({ id: item._id, path: "asset" })}>Delete</button>
                                </div>
                            </div>
                        </BookItem>
                    )
                })}
            </ul>
            <br></br>
            <br></br>
            <br></br>
        </div>
    )
}
