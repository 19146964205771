import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './Product.module.css';
import ChangeProductForm from '../components/Bookings/ChangeProductForm';
import Card from '../components/ui/Card';
import DisplayField from '../components/ui/DisplayField';
import GetAPI from '../api/GetAPI';
import PutAPI from '../api/PutAPI';

export default function ChangeProductPage(props) {
    console.log("ChangeProductPageprops: " + JSON.stringify(props));
    const params = useParams();
    console.log("params: " + JSON.stringify(params));
    const [targetId, setTargetId] = useState(params.productId);
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState();
    const [loadedFirstAsset, setLoadedFirstAsset] = useState({});
    const [loadedSecondAsset, setLoadedSecondAsset] = useState({});
    const PAGE_URL = 'product';

    async function getFirstPart(loadedData) {
        if (loadedData?.firstPart) {
            let isMounted = true
            console.log("getFirstPartId: " + JSON.stringify(loadedData.firstPart));
            await GetAPI('asset/' + loadedData.firstPart)
                .then((data) => {
                    if (isMounted) {
                        console.log("getFirstPartLoadedData: " + JSON.stringify(data));
                        setLoadedFirstAsset(data);
                    }
                })
            return () => {
                isMounted = false
            }
        }
    }

    async function getSecondPart(loadedData) {
        let isMounted = true
        if (loadedData?.secondPart) {
            console.log("getSecondPartId: " + JSON.stringify(loadedData.secondPart));
            await GetAPI('asset/' + loadedData.secondPart)
                .then((data) => {
                    if (isMounted) {
                        console.log("getSecondPartLoadedData: " + JSON.stringify(data));
                        setLoadedSecondAsset(data);
                        setIsLoading(false)
                    }
                })
        }
        return () => {
            isMounted = false
        }
    }

    async function getData(targetId) {
        setIsLoading(true);
        let isMounted = true
        await GetAPI(PAGE_URL + '/' + targetId)
            .then((data) => {
                if (isMounted) {
                    console.log("LoadedData: " + JSON.stringify(data));
                    setLoadedData(data);
                }
            })
        return () => {
            isMounted = false
        }
    }

    useEffect(() => {
        getData(targetId)
    }, [targetId])

    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            getFirstPart(loadedData);
            getSecondPart(loadedData);

        }
        return () => {
            isMounted = false;
        }
    }, [loadedData])

    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }

    async function storeChangedDataHandler(bodyData) {
        const fullNewData = {
            id: targetId,
            name: bodyData.name,
            relation: bodyData.relation,
            articleNumber: bodyData.articleNumber,
            taxRate: bodyData.taxRate,
            firstChoice: bodyData.firstChoice,
            firstPart: bodyData.firstPart,
            doFirstPart: bodyData.doFirstPart,
            secondChoice: bodyData.secondChoice,
            secondPart: bodyData.secondPart,
            doSecondPart: bodyData.doSecondPart,
            doTax: bodyData.doTax,
            reference: bodyData.reference
        }
        console.log("sending: " + JSON.stringify(fullNewData));
        await PutAPI(PAGE_URL, fullNewData)
            .then(() => {
                navigate("/product");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    console.log("ChangeProduct - loadedData: " + JSON.stringify(loadedData));
    return (
        <div className={classes.product}>
            <h1>Change Product</h1>
            <Card className={classes.card}>
                <h1>Current values</h1>
                <DisplayField type='text' label='Id' value={loadedData._id} readOnly={true} />
                <DisplayField type='text' label='Name' value={loadedData.name} readOnly={true} />
                <DisplayField type='text' label='Relation' value={loadedData.relation.name} readOnly={true} />
                <DisplayField type='text' label='ArticleNumber' value={loadedData.articleNumber} readOnly={true} />
                <DisplayField type='text' label='taxRate' value={loadedData.taxRate.name} readOnly={true} />
                <DisplayField type='text' label='firstChoice' value={loadedData.firstChoice} readOnly={true} />
                <DisplayField type='text' label='firstPart' value={loadedFirstAsset?.name} readOnly={true} />
                <DisplayField type='text' label='doFirstPart' value={loadedData.doFirstPart} readOnly={true} />
                <DisplayField type='text' label='secondChoice' value={loadedData.secondChoice} readOnly={true} />
                <DisplayField type='text' label='secondPart' value={loadedSecondAsset?.name} readOnly={true} />
                <DisplayField type='text' label='doSecondPart' value={loadedData.doSecondPart} readOnly={true} />
                <DisplayField type='text' label='doTax' value={loadedData.doTax} readOnly={true} />
                <DisplayField type='text' label='reference' value={loadedData.reference} readOnly={true} />
            </Card>
            <ChangeProductForm onStoreChangedData={storeChangedDataHandler}
                name={loadedData.name}
                relationId={loadedData.relation._id}
                relationName={loadedData.relation.name}
                articleNumber={loadedData.articleNumber}
                taxId={loadedData.taxRate._id}
                taxName={loadedData.taxRate.name}
                firstChoice={loadedData.firstChoice}
                firstPart={loadedFirstAsset?._id}
                doFirstPart={loadedData.doFirstPart}
                secondChoice={loadedData.secondChoice}
                secondPart={loadedSecondAsset?._id}
                doSecondPart={loadedData.doSecondPart}
                doTax={loadedData.doTax}
                reference={loadedData.reference}
            />
        </div>
    );
}