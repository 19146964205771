import { useRef, useState } from "react";

import Card from "../ui/Card";
import classes from "./NewTaxrateForm.module.css";

export default function ChangeAssetForm(props) {
    const nameInputRef = useRef();
    const [selectedType, setType] = useState();
    const [selectedSide, setSide] = useState();
    const [selectedCategory, setCategory] = useState();

    function submitHandler(event) {
        event.preventDefault();

        const enteredName = nameInputRef.current.value;

        const newData = {
            name: enteredName,
            type: selectedType,
            side: selectedSide,
            category: selectedCategory
        };
        props.onStoreChanged(newData);
    }

    return <Card className={classes.card}>
        <form className={classes.card} onSubmit={submitHandler}>
            <div className={classes.card}>
                <label htmlFor='title'>New Name</label>
                <input type='text' required id='name'
                    defaultValue={props.assetName}
                    ref={nameInputRef} />
            </div>
            <div>
                <label htmlFor='title'>Type</label>
                <select value={selectedType} required id='type' onChange={e => setType(e.target.value)}>
                    <option key="assetType" value={props.assetType}>{props.assetType}</option>
                    {props.assetType === "Resultatenrekening" ?
                        <option key="balans" value="Balans">Balans</option>
                        :
                        <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    }
                </select>
            </div>
            <div>
                <label htmlFor='title'>Side</label>
                <select value={selectedSide} required id='type' onChange={e => setSide(e.target.value)}>
                    <option key="assetSide" value={props.assetSide}>{props.assetSide}</option>
                    {props.assetSide === "Credit" ?
                        <option key="debet" value="Debet">Debet</option>
                        :
                        <option key="credit" value="Credit">Credit</option>
                    }
                </select>
            </div>
            <div>
                <label htmlFor='title'>Category</label>
                <select value={selectedCategory} required id='type' onChange={e => setCategory(e.target.value)}>
                    <option key="assetCategory" value={props.assetCategory}>{props.assetCategory}</option>
                    {props.assetCategory === "Eigen Vermogen" ?
                        <><option key="vasteActiva" value="Vaste Activa">Vaste Activa</option>
                            <option key="vlottendeActiva" value="Vlottende Activa">Vlottende Activa</option>
                            <option key="liquideMiddelen" value="Liquide Middelen">Liquide Middelen</option>
                            <option key="kvv" value="Kort Vreemd Vermogen">Kort Vreemd Vermogen</option>
                            <option key="kosten" value="Kosten">Kosten</option>
                            <option key="opbrengsten" value="Opbrengsten">Opbrengsten</option>
                        </>
                        :
                        <><option key="vasteActiva" value="Vaste Activa">Vaste Activa</option>
                            <option key="vlottendeActiva" value="Vlottende Activa">Vlottende Activa</option>
                            <option key="liquideMiddelen" value="Liquide Middelen">Liquide Middelen</option>
                            <option key="eigenVermogen" value="Eigen Vermogen">Eigen Vermogen</option>
                            <option key="kvv" value="Kort Vreemd Vermogen">Kort Vreemd Vermogen</option>
                            <option key="kosten" value="Kosten">Kosten</option>
                            <option key="opbrengsten" value="Opbrengsten">Opbrengsten</option>
                        </>
                    }
                </select>
            </div>
            <div className={classes.card}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}


