import axios from "./Axios";

export default async function GetAPI(callUrl) {
    console.log("Calling: " + callUrl);
    let apiData
    try {
        await axios.get(callUrl).then(data => {
            console.log("returnedData: " + JSON.stringify(data.status));
            apiData = data.data;
        });
    } catch (error) {
        console.log("status: " + JSON.stringify(error.response.status));
        if (error.response.status === 403) {
            console.log("Token expired");
        } else {
            console.error(error);
        }
    }
         return apiData;
}