import { useNavigate } from 'react-router-dom';
import classes from './Relation.module.css';
import NewRelationForm from '../components/Bookings/NewRelationForm';
import PostAPI from '../api/PostAPI';

export default function NewRelationPage() {
    const navigate = useNavigate();
    const PAGE_URL = 'relation';
    async function storeNewRelationHandler(newData) {
        await PostAPI(PAGE_URL, newData)
            .then(() => {
                navigate("/relation");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.relation}>
            <h1>Create New Relation</h1>
            <NewRelationForm onStoreNewRelation={storeNewRelationHandler} />
        </div>
    );
}