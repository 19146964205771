import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Taxrate.module.css';
import BookItem from '../components/Bookings/BookItem';
import ExternalDeleteHandler from '../components/DeleteHandler';
import GetAPI from '../api/GetAPI';

export default function TaxRate() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'taxrate';

    async function getData() {
        setIsLoading(true);
        await GetAPI(PAGE_URL)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [PAGE_URL])

    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }

    function newHandler(event) {
        event.preventDefault();
        navigate("/newtaxrate");
    }

    function changeHandler(event) {
        event.preventDefault();
        console.log(event.target.value);
        const pLoad = JSON.parse(event.target.value)
        console.log(pLoad.name);
        navigate("/changetaxrate/" + pLoad.id);
    }

    function passDeleteHandler(event) {
        ExternalDeleteHandler(event)
            .then((result) => {
                console.log("output: " + result);
                navigate(0);
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.tax}>
            <h1>All Taxrates</h1>
            <ul>
                <div className={classes.newBtnDiv} >
                    <button className={classes.navBtn} onClick={newHandler}>Create New Taxrate</button>
                </div>
                {loadedData.map((booking) => {
                    return (
                        <BookItem key={booking._id}>
                            <div className={classes.bookingtable} key={booking._id} >
                                <div className={classes.col2}>{booking.name}</div>
                                <div className={classes.col3}>{booking.tax}</div>
                                <div className={classes.actions}>
                                    <button className={classes.btn} onClick={changeHandler} value={JSON.stringify({ id: booking._id, name: booking.name, tax: booking.tax })}>Change</button>
                                    <button className={classes.btn} onClick={passDeleteHandler} value={JSON.stringify({ id: booking._id, path: "taxrate" })}>Delete</button>
                                </div>
                            </div>
                        </BookItem>
                    )
                })}
            </ul>
        </div>
    )
}