export const msalConfig = {
    auth: {
      clientId: "d917de1a-a344-4b1a-ab3c-e8363dd5d59b",
      authority: "https://login.microsoftonline.com/dca1571c-3a40-463e-859d-96017d18c810", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
      redirectUri: "https://365euro.vincent365.nl",
    //   clientSecret: "ENTER_CLIENT_SECRET",
    },
    cache: {
      cacheLocation: "sessionStorage", // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};

 // Add scopes here for ID token to be used at Microsoft identity platform endpoints.
 export const loginRequest = {
  scopes: []
 };