import { useRef, useState, useEffect } from "react";
import Card from "../ui/Card";
import classes from "./NewBookingForm.module.css";
import GetAPI from "../../api/GetAPI";

export default function ChangeBookingForm(props) {
    const dateInputRef = useRef();
    const bookingNumberInputRef = useRef(props.bookingNumber);
    const [bookingNumber, setBookingNumber] = useState(props.bookingNumber);
    const [bookingDate, setBookingDate] = useState();
    const [selectedRelation, setRelation] = useState(props.relationId);
    const [loadedRelations, setLoadedRelations] = useState([]);
    const [selectedProduct, setProduct] = useState(props.productId);
    const [loadedProduct, setLoadedProduct] = useState([]);
    const [bookingPrice, setBookingPrice] = useState(props.price);
    const [selectedProductTaxRate, setProductTaxRate] = useState(props.taxRateTax);
    const [loadedTax, setLoadedTax] = useState([]);
    const [selectedTaxRate, setTaxRate] = useState(props.taxRateId);
    const [bookingTaxAmount, setBookingTaxAmount] = useState(props.taxAmount);
    const [bookingTotal, setBookingTotal] = useState(props.total);
    const [selectedFirstChoice, setFirstChoice] = useState();
    const [selectedFirstPart, setFirstPart] = useState(props.firstPart);
    const [loadedAsset, setLoadedAsset] = useState([]);
    const [selectedDoFirstPart, setDoFirstPart] = useState(props.doFirstPart);
    const [selectedSecondChoice, setSecondChoice] = useState();
    const [selectedSecondPart, setSecondPart] = useState();
    const [selectedDoSecondPart, setDoSecondPart] = useState(props.doSecondPart);
    const [selectedDoTax, setDoTax] = useState(props.doTax);
    const [selectedReference, setReference] = useState();
    const referenceInputRef = useRef(props.reference);
    const [isLoading, setIsLoading] = useState(true);
    let taxMP = 0;

    async function getRelation() {
        await GetAPI('relation')
            .then((data) => {
                if (data.length) {
                    setLoadedRelations(data);
                }
            })
    }

    async function getAsset() {
        await GetAPI('asset')
            .then((data) => {
                setLoadedAsset(data);
            })
    }

    useEffect(() => {
        setIsLoading(true);
        getRelation();
        getAsset();
    }, [])

    async function getLoadedProduct(selectedRelation) {
        if (selectedRelation) {
            await GetAPI('product/relation/' + selectedRelation)
                .then((data) => {
                    if (data.length) {
                        setLoadedProduct(data);
                        if (data.length === 1) {
                            console.log("Products: " + JSON.stringify(data));
                            setProduct(data[0]._id);
                            // setFirstChoice(data[0].firstChoice);
                            // setFirstPart(data[0].firstPart);
                            // setDoFirstPart(data[0].doFirstPart);
                            // setSecondChoice(data[0].secondChoice);
                            // setSecondPart(data[0].secondPart);
                            // setDoSecondPart(data[0].doSecondPart);
                            // setDoTax(data[0].doTax);
                            // setReference(data[0].reference);
                        }
                    } else {
                        setLoadedProduct([]);
                    }
                })
        }
    }

    useEffect(() => {
        getLoadedProduct(selectedRelation);
    }, [selectedRelation])

    async function getSelectedProduct(selectedProduct, bookingPrice, props, selectedProductTaxRate
    ) {
        if (selectedProduct) {
            await GetAPI('product/' + selectedProduct)
                .then((data) => {
                    if (data) {
                        setTaxRate(data.taxRate._id);
                        setProductTaxRate(data.taxRate.tax);
                        setLoadedTax([data.taxRate]);
                        setFirstChoice(data.firstChoice);
                        setFirstPart(data.firstPart);
                        setDoFirstPart(data.doFirstPart);
                        setSecondChoice(data.secondChoice);
                        setSecondPart(data.secondPart);
                        setDoSecondPart(data.doSecondPart);
                        setDoTax(data.doTax);
                        setReference(data.reference);
                        if (bookingPrice) {
                            console.log("bookingPrice: " + bookingPrice)
                            setBookingTaxAmount(parseFloat(+((selectedProductTaxRate / 100) * (bookingPrice))).toFixed(2));
                            setBookingTotal(parseFloat(+((1 + (selectedProductTaxRate / 100)) * bookingPrice)).toFixed(2));
                        }
                        else {
                            setBookingTaxAmount(parseFloat(+((selectedProductTaxRate / 100) * (props.price))).toFixed(2));
                            setBookingTotal(parseFloat(+((1 + (selectedProductTaxRate / 100)) * (props.price))).toFixed(2));
                        }
                    } else {
                        setProductTaxRate('')
                    }
                })
        }
    }

    useEffect(() => {
        getSelectedProduct(selectedProduct, bookingPrice, props, selectedProductTaxRate
        )
    }, [selectedProduct, bookingPrice, props, selectedProductTaxRate
    ])


    // function setTaxAndTotalOnPriceChange(props, selectedProductTaxRate) {
    //     setBookingTaxAmount(parseFloat(+((selectedProductTaxRate / 100) * (props.price))).toFixed(2));
    //     setBookingTotal(parseFloat(+((1 + (selectedProductTaxRate / 100)) * props.price)).toFixed(2));
    // }

    // useEffect(() => {
    //     setTaxAndTotalOnPriceChange(props, selectedProductTaxRate)
    // }, [props, selectedProductTaxRate])

    useEffect(() => {
        setBookingNumber(props.bookingNumber);
    }, [props.bookingNumber])

    useEffect(() => {
        if (props.bookingDate) {
            setBookingDate(props.bookingDate.split('T')[0]);
        }
        setIsLoading(false);
    }, [props.bookingDate])

    if (isLoading) {
        return (
            <section>
                <p>'ChangeBookingForm'</p>
                <p>Loading....</p>
            </section>
        );
    }

    function submitHandler(event) {
        let newRelation = selectedRelation;
        let newproduct = selectedProduct;
        let newBookingPrice = bookingPrice;
        let newTaxRate = selectedTaxRate;
        let newTaxAmount = bookingTaxAmount;
        let newTotal = bookingTotal;
        let newFirstPart = selectedFirstPart;
        let newDoFirstPart = selectedDoFirstPart;
        let newSecondPart = selectedSecondPart;
        let newDoSecondPart = selectedDoSecondPart;
        let newDoTax = selectedDoTax;
        // let newRelation = props.relationId;
        // let newproduct = props.productId;
        // let newBookingPrice = props.price;
        // let newTaxRate = props.taxRateId;
        // let newTaxAmount = props.taxAmount;
        // let newTotal = props.total;
        // let newFirstPart = props.firstPart;
        // let newDoFirstPart = props.doFirstPart;
        // let newSecondPart = props.secondPart;
        // let newDoSecondPart = props.doSecondPart;
        // let newDoTax = props.doTax;
        // if (selectedRelation) { newRelation = selectedRelation }
        // if (selectedProduct) { newproduct = selectedProduct; }
        // if (bookingPrice) { newBookingPrice = bookingPrice; }
        // if (selectedTaxRate) { newTaxRate = selectedTaxRate; }
        // if (bookingTaxAmount) { newTaxAmount = bookingTaxAmount; }
        // if (bookingTotal) { newTotal = bookingTotal; }
        // if (selectedFirstPart) { newFirstPart = selectedFirstPart } // else {newFirstPart = props.firstPart }
        // if (selectedDoFirstPart) { newDoFirstPart = selectedDoFirstPart; }
        // if (selectedSecondPart) { newSecondPart = selectedSecondPart; }
        // if (selectedDoSecondPart) { newDoSecondPart = selectedDoSecondPart; }
        // if (selectedDoTax) { newDoTax = selectedDoTax; }
        event.preventDefault();
        const enteredBookingNumber = bookingNumberInputRef.current.value;
        const enteredDate = dateInputRef.current.value;
        const enteredReference = referenceInputRef.current.value;
        const bodyData = {
            bookingNumber: enteredBookingNumber,
            bookingDate: enteredDate,
            relation: newRelation,
            product: newproduct,
            price: newBookingPrice,
            taxRate: newTaxRate,
            taxAmount: newTaxAmount,
            total: newTotal,
            firstPart: newFirstPart,
            doFirstPart: newDoFirstPart,
            secondPart: newSecondPart,
            doSecondPart: newDoSecondPart,
            doTax: newDoTax,
            reference: enteredReference
        };
        props.onStoreChangedData(bodyData);
    }

    return <Card className={classes.card}>
        <form className={classes.card} onSubmit={submitHandler}>
            <div className={classes.control}>
                <label htmlFor='title'>BookingNumber</label>
                <input type='text' required id='bookingNumber'
                    defaultValue={bookingNumber} ref={bookingNumberInputRef} 
                    readOnly={false} />
            </div>

            <div>
                <label htmlFor='title'>Date</label>

                <input type='date' required id='bookingDate'
                    defaultValue={bookingDate}
                    ref={dateInputRef} />
            </div>

            <div>
                <label htmlFor='title'>Relation</label>
                <select value={selectedRelation} required id='relation' onChange={e => setRelation(e.target.value)}>
                    {!selectedRelation &&
                        <option key={props.relationId}
                            defaultValue={props.relationId}>{props.relationName}</option>
                    }
                    {loadedRelations.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Product</label>
                <select value={selectedProduct} required id='product' onChange={e => { setProduct(e.target.value) }}>
                    {!selectedProduct &&
                        <option key={props.productId}
                            defaultValue={props.productId}>{props.product.name}</option>
                    }
                    {loadedProduct.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Price</label>
                <input type='number' step='any' value={bookingPrice} required id='bookingPrice'
                    onChange={e => {
                        console.log("taxrate: " + selectedProductTaxRate)

                        setBookingPrice(e.target.value);
                        { selectedProductTaxRate ? taxMP = selectedProductTaxRate : taxMP = props.taxRateTax }
                        setBookingTaxAmount(+(((taxMP / 100)) * (e.target.value)).toFixed(2));
                        console.log("bookingPrice: " + bookingPrice)
                        console.log("e.target.value: " + e.target.value)
                        console.log("bookingTotal: " + bookingTotal)
                    }} />
            </div>

            <div>
                <label htmlFor='title'>taxRate</label>
                <select value={selectedTaxRate} required id='taxRate' onChange={e => {
                    setTaxRate(e.target.value)
                    { selectedProductTaxRate ? taxMP = selectedProductTaxRate : taxMP = props.taxRateTax }
                    setBookingTaxAmount(+(((selectedProductTaxRate / 100)) * (bookingPrice)).toFixed(2));
                }
                }>
                    {!selectedTaxRate &&
                        <option key={props.taxRateId}
                            defaultValue={props.taxRateId}>{props.taxRateName}</option>
                    }
                    {loadedTax.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>TaxAmount</label>
                <input type='number' value={bookingTaxAmount} required id='taxAmount'
                    readOnly={true} />
            </div>

            <div>
                <label htmlFor='title'>Total</label>
                <input type='number' value={bookingTotal} required id='bookingTotal'
                    readOnly={true}
                />
            </div>

            <div>
                <label htmlFor='title'>FirstChoice</label>
                <select value={selectedFirstChoice} id='firstChoice' onChange={e => {
                    setFirstChoice(e.target.value)
                    setFirstPart("")
                    setDoFirstPart("")
                }
                }>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>FirstPart</label>
                <select value={selectedFirstPart}
                    required id='firstPart' onChange={e => setFirstPart(e.target.value)}>
                    <option key="none" value=""></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedFirstChoice) {
                            return (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoFirstPart}
                    required id='doFirst' onChange={e => setDoFirstPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="substract" value="substract">Substract</option>
                    <option key="add" value="add">Add</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondChoice</label>
                <select value={selectedSecondChoice} id='secondChoice' onChange={e => {
                    setSecondChoice(e.target.value)
                    setSecondPart("");
                    setDoSecondPart("");
                }
                }>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondPart</label>
                <select value={selectedSecondPart}
                    required id='secondPart' onChange={e => setSecondPart(e.target.value)}>
                    <option key="none" value=""></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedSecondChoice) {
                            return (
                                <option key={item._id} value={item._id}>{item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoSecondPart}
                    required id='doSecond' onChange={e => setDoSecondPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="substract" value="substract">Substract</option>
                    <option key="add" value="add">Add</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>TaxAction</label>
                <select value={selectedDoTax}
                    id='taxAction' onChange={e => setDoTax(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="recieve" value="recieve">Recieve</option>
                    <option key="pay" value="pay">Pay</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>Reference</label>
                <input type='text' required id='reference' defaultValue={props.reference} ref={referenceInputRef} />
            </div>
            <div className={classes.action}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}
