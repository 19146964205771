import axios from "./Axios";

export default async function PutAPI(callUrl, bodyData) {
    // console.log("Calling: " + callUrl);
    // console.log("bodyData: " + JSON.stringify(bodyData));
    const configHeaders = {
        "content-type": "application/json",
        "Accept": "application/json"
    };
    try {
        await axios({
            url: callUrl,
            method: 'put',
            data: bodyData,
            mode: "cors",
            headers: configHeaders
        })
    } catch (error) {
        console.error(error);
    }
    return;
}