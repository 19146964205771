import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './Booking.module.css';
import ChangeBookingForm from '../components/Bookings/ChangeBookingForm';
import Card from '../components/ui/Card';
import DisplayField from '../components/ui/DisplayField';
import GetAPI from '../api/GetAPI';
import PutAPI from '../api/PutAPI';

export default function ChangeBookingPage(props) {
    const params = useParams();
    const targetId = params.bookingId;
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'booking';

    async function getData(targetId) {
        setIsLoading(true);
        await GetAPI(PAGE_URL + '/details/' + targetId)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData(targetId)
    }, [PAGE_URL, targetId])

    useEffect(() => {
        if (loadedData.bookingDate) {
            loadedData.bookingDate = (loadedData.bookingDate.split('T')[0]);
        }
    }
        , [loadedData])

    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }

    async function storeChangedDataHandler(bodyData) {
        const fullNewData = {
            id: targetId,
            bookingNumber: bodyData.bookingNumber,
            bookingDate: bodyData.bookingDate,
            relation: bodyData.relation,
            product: bodyData.product,
            price: bodyData.price,
            taxRate: bodyData.taxRate,
            taxAmount: bodyData.taxAmount,
            total: bodyData.total,
            firstPart: bodyData.firstPart,
            doFirstPart: bodyData.doFirstPart,
            secondPart: bodyData.secondPart,
            doSecondPart: bodyData.doSecondPart,
            doTax: bodyData.doTax,
            reference: bodyData.reference
        }
        console.log("sending: " + JSON.stringify(fullNewData));
        await PutAPI(PAGE_URL, fullNewData)
            .then((result) => {
                navigate("/booking");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.booking}>
            <h1>Change Booking</h1>
            <Card className={classes.card}>
                <h1>Current values</h1>
                <DisplayField type='text' key="id" label='id' value={loadedData._id} readOnly={true} />
                <DisplayField type='text' key='bookingNumber' label='bookingNumber' value={loadedData.bookingNumber} readOnly={true} />
                <DisplayField type='text' label='bookingDate' value={loadedData.bookingDate} readOnly={true} />
                {loadedData.relation ?
                    <DisplayField type='text' label='relation' value={loadedData.relation.name} readOnly={true} />
                    : <DisplayField type='text' label='relation' value="" readOnly={true} />}
                {loadedData.product ?
                    <DisplayField type='text' label='product' value={loadedData.product.name} readOnly={true} />
                    : <DisplayField type='text' label='product' value="" readOnly={true} />}

                <DisplayField type='text' label='price' value={loadedData.price} readOnly={true} />
                {loadedData.taxRate ?
                    <DisplayField type='text' label='taxRate' value={loadedData.taxRate.name} readOnly={true} />
                    : <DisplayField type='text' label='taxRate' value="" readOnly={true} />}
                <DisplayField type='text' label='taxAmount' value={loadedData.taxAmount} readOnly={true} />
                <DisplayField type='text' label='total' value={loadedData.total} readOnly={true} />
                {loadedData.firstPart ?
                    <DisplayField type='text' label='firstPart' value={loadedData.firstPart.name} readOnly={true} />
                    : <DisplayField type='text' label='firstPart' value="" readOnly={true} />}
                <DisplayField type='text' label='doFirstPart' value={loadedData.doFirstPart} readOnly={true} />
                {loadedData.secondPart ?
                    <DisplayField type='text' label='secondPart' value={loadedData.secondPart.name} readOnly={true} />
                    : <DisplayField type='text' label='secondPart' value="" readOnly={true} />}
                <DisplayField type='text' label='doSecondPart' value={loadedData.doSecondPart} readOnly={true} />
                {loadedData.doTax ? <DisplayField type='text' label='doTax' value={loadedData.doTax} readOnly={true} />
                    : <DisplayField type='text' label='doTax' value="" readOnly={true} />
                }
                <DisplayField type='text' label='reference' value={loadedData.reference} readOnly={true} />
            </Card>
            {loadedData.secondPart ?
                <ChangeBookingForm onStoreChangedData={storeChangedDataHandler} bookingId={loadedData._id}
                    bookingNumber={loadedData.bookingNumber} bookingDate={loadedData.bookingDate}
                    relationId={loadedData.relation._id} relationName={loadedData.relation.name} product={loadedData.product} productId={loadedData.product._id}
                    price={loadedData.price} taxRateId={loadedData.taxRate._id} taxRateName={loadedData.taxRate.name} taxRateTax={loadedData.taxRate.tax} taxAmount={loadedData.taxAmount} total={loadedData.total}
                    firstPart={loadedData.firstPart} doFirstPart={loadedData.doFirstPart} secondPart={loadedData.secondPart} doSecondPart={loadedData.doSecondPart} doTax={loadedData.doTax} reference={loadedData.reference}
                />
                : <ChangeBookingForm onStoreChangedData={storeChangedDataHandler} bookingId={loadedData._id} />
            }
        </div>
    );
}

