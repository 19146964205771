import { useNavigate } from 'react-router-dom';
import classes from './Relation.module.css';
import NewProductForm from '../components/Bookings/NewProductForm';
import PostAPI from '../api/PostAPI';

export default function NewProductPage() {
    const navigate = useNavigate();
    const PAGE_URL = 'product';
    async function storeNewRecordHandler(newData) {
        await PostAPI(PAGE_URL, newData)
            .then(() => {
                navigate("/product");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.relation}>
            <h1>Create New Product</h1>
            <NewProductForm onStoreNewRecord={storeNewRecordHandler} />
        </div>
    );
}