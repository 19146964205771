import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Relation.module.css';
import BookItem from '../components/Bookings/BookItem';
import ExternalDeleteHandler from '../components/DeleteHandler';
import GetAPI from '../api/GetAPI';

export default function Relaties() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'relation';

    async function getData() {
        setIsLoading(true);
        await GetAPI(PAGE_URL)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [PAGE_URL])

    if (isLoading) {
        return (
            <section>
                <p>{PAGE_URL}</p>
                <p>Loading....</p>
            </section>
        );
    }

    function newHandler(event) {
        event.preventDefault();
        navigate("/newrelation");
    }

    function changeHandler(event) {
        event.preventDefault();
        console.log(event.target.value);
        const pLoad = JSON.parse(event.target.value)
        navigate("/changerelation/" + pLoad.id);
    }

    function passDeleteHandler(event) {
        ExternalDeleteHandler(event)
            .then((result) => {
                console.log("output: " + result);
                navigate(0);
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.relation}>
            <h1>All Relations</h1>
            <ul>
                <div className={classes.newBtnDiv} >
                    <button className={classes.navBtn} onClick={newHandler}>Create New Relation</button>
                </div>
                {loadedData.map((item) => {
                    return (
                        <BookItem key={item._id}>
                            <div className={classes.bookingtable} key={item._id} >
                                <div className={classes.colName}>{item.name}</div>
                                <div className={classes.colAddress}>{item.address}</div>

                                <div className={classes.actions}>
                                    <button className={classes.btn} onClick={changeHandler} value={JSON.stringify({ id: item._id, name: item.name, address: item.address })}>Change</button>
                                    <button className={classes.btn} onClick={passDeleteHandler} value={JSON.stringify({ id: item._id, path: "relation" })}>Delete</button>
                                </div>
                            </div>
                        </BookItem>
                    )
                })}
            </ul>
        </div>
    )
}