import classes from './DisplayField.module.css';

export default function DisplayField(props) {
    console.log(props);
    if (props.className) {
        return <div className={props.className}>{props.children}
            <label htmlFor='title'>{props.label}</label>
            <input type={props.type} id={props.label} value={props.value} readOnly={props.readOnly} />
        </div>
    } else {
        return <div className={classes.displayfield}>{props.children}
            <label htmlFor='title'>{props.label}</label>
            <input type={props.type} id={props.label} value={props.value} readOnly={props.readOnly} />
        </div>
    }
}

