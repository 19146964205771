import { useRef } from "react";
import Card from "../ui/Card";
import classes from "./NewTaxrateForm.module.css";

export default function NewTaxrateForm(props) {
    const nameInputRef = useRef();
    const taxInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        const enteredName = nameInputRef.current.value;
        const enteredTax = taxInputRef.current.value;
        const taxData = {
            name: enteredName,
            tax: enteredTax
        };
        props.onStoreNewTaxrate(taxData);
    }

    return <Card className={classes.card}>
        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>
                <label htmlFor='title'>Name</label>
                <input type='text' required id='name' ref={nameInputRef} />
            </div>
            <div className={classes.control}>
                <label htmlFor='title'>Tax</label>
                <input type='number' required id='tax' ref={taxInputRef} />
            </div>
            <div className={classes.action}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}