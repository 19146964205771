// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BookItem_item__OEq6z {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center; \r\n}\r\n\r\n.BookItem_actions__GKc4U {\r\n    float: right;\r\n    }\r\n\r\n", "",{"version":3,"sources":["webpack://./src/components/Bookings/BookItem.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ","sourcesContent":[".item {\r\n    width: 100%;\r\n    display: flex;\r\n    align-items: center; \r\n}\r\n\r\n.actions {\r\n    float: right;\r\n    }\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"item": "BookItem_item__OEq6z",
	"actions": "BookItem_actions__GKc4U"
};
export default ___CSS_LOADER_EXPORT___;
