import { useState, useEffect } from 'react';
import ApiUrl from '../ApiUrl';
import BookItem from '../components/Bookings/BookItem';
import classes from './Balance.module.css';
import axios from '../api/Axios';
import YearSelect from '../helpers/YearSelector';

export default function Balans() {
  const [isLoading, setIsLoading] = useState(true);
  const [loadedData, setLoadedData] = useState([]);
  const [loadedProfit, setLoadedProfit] = useState([]);
  const API_URL = ApiUrl();
  const [selectedYear, setSelectedYear] = useState(YearSelect());

  let totalDebt = 0;
  let totalCredit = 0;
  let totalVasteActiva = 0;
  let totalVlottendeActiva = 0;
  let investeringsAfschrijving = 0;
  let overigeVasteActiva = 0;
  let totalLiquideMiddelen = 0;
  let totalEV = 0;
  let totalKVV = 0;
  let cssClass = "";

  // async function getData() {
  //   setIsLoading(true);
  //   try {
  //     await axios.get('balance/'  + selectedYear).then(bData => {
  //       console.log("balanceData-2: " + JSON.stringify(bData.data));
  //       setLoadedData(bData.data);
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   try {
  //     await axios.get('profit').then(pData => {
  //       console.log("profitData-2: " + JSON.stringify(pData.data));
  //       setLoadedProfit(pData.data);
  //     });
  //   } catch (error) {
  //     console.error(error);
  //   }

  //   setIsLoading(false);
  // }

  useEffect(() => {
    async function getData() {
      setIsLoading(true);
      try {
        await axios.get('balance/' + selectedYear).then(bData => {
          console.log("balanceData-2: " + JSON.stringify(bData.data));
          setLoadedData(bData.data);
        });
      } catch (error) {
        console.error(error);
      }

      try {
        await axios.get('profit/' + selectedYear).then(pData => {
          console.log("profitData-2: " + JSON.stringify(pData.data));
          setLoadedProfit(pData.data);
        });
      } catch (error) {
        console.error(error);
      }

      setIsLoading(false);
    }
    getData();
  }, [API_URL, selectedYear])

  function previousYearHandler(event) {
    event.preventDefault();
    setSelectedYear(selectedYear - 1)
  }

  function nextYearHandler(event) {
    event.preventDefault();
    setSelectedYear(selectedYear + 1)
  }

  if (isLoading) {
    return (
      <section>
        <p>{API_URL} + 'Balance'</p>
        <p>Loading....</p>
      </section>
    );
  }
  return (
    <div className={classes.base}>
      <div className={classes.navRow}>
        <button className={classes.navBtn} onClick={previousYearHandler}>Previous Year</button>
        <h1>Balans {selectedYear}</h1>
        <button className={classes.navBtn} onClick={nextYearHandler}>Next Year</button>
      </div>
      <ul>
        <div className={classes.bookingtable} >
          <BookItem key='titleRow' className={classes.card}>
            <div className={classes.titlerow}>
              <div className={classes.title}>Debet</div>
              <div className={classes.title}>Credit</div>
            </div>
            <div className={classes.balanceTable}>
              <div className={classes.balanceTableDebt}>
                <h3>Vaste Activa</h3>
                {loadedData.sort( (b, a) => a.name.localeCompare(b.name) ).map((item) => {
                  if ((item.side === "Debet") && (+item.balance.toFixed(2) !== 0)) {
                    cssClass = classes.debt;
                    totalDebt += item.balance;
                  }
                  if (item.category === "Vaste Activa") {
                    if ((item.side === "Debet") && (+item.balance.toFixed(2) !== 0)) {
                      cssClass = classes.debt;
                      if (item.name === "IT-middelen Investeringswaarde") {
                        totalVasteActiva += (+item.balance.toFixed(2));
                        return (
                          <div className={cssClass} key={item._id}>
                            <div className={classes.title}>{item.name}</div>
                            <div className={classes.value}>{totalVasteActiva}</div>
                          </div>
                        )
                      } else {
                        if (item.name === "IT-middelen Afschrijvingen") {
                          investeringsAfschrijving += (+item.balance.toFixed(2));
                          return (
                            <div className={cssClass} key={item._id}>
                              <div className={classes.title}>{item.name}</div>
                              <div className={classes.value}>{investeringsAfschrijving}</div>
                            </div>
                          )
                        } else {
                          overigeVasteActiva += (+item.balance.toFixed(2));
                          return (
                            <div className={cssClass} key={item._id}>
                              <div className={classes.title}>{item.name}</div>
                              <div className={classes.value}>{overigeVasteActiva}</div>
                            </div>
                          )
                        }
                      }
                    }
                    else {
                      return (null)
                    }
                  }
                  else {
                    return (null)
                  }
                })}
                <div className={classes.debt} key="totalVastId">
                  <div className={classes.boldTitle}>Totaal Vaste Activa</div>
                  <div className={classes.boldValue}>{+((totalVasteActiva + investeringsAfschrijving + overigeVasteActiva).toFixed(2))}</div>
                </div>

                <h3>Vlottende Activa</h3>
                {loadedData.map((item) => {
                  if (item.category === "Vlottende Activa") {
                    if ((item.side === "Debet") && (+item.balance.toFixed(2) !== 0)) {
                      totalVlottendeActiva += (+item.balance.toFixed(2));
                      cssClass = classes.debt;
                      return (
                        <div className={cssClass} key={item._id}>
                          <div className={classes.title}>{item.name}</div>
                          <div className={classes.value}>{(+item.balance.toFixed(2))}</div>
                        </div>
                      )
                    }
                    else {
                      return (null)
                    }
                  }
                  else {
                    return (null)
                  }
                })}
                <div className={classes.debt} key="totalVlottendId">
                  <div className={classes.boldTitle}>Totaal Vlottende Activa</div>
                  <div className={classes.boldValue}>{totalVlottendeActiva}</div>
                </div>

                <h3>Liquide Middelen</h3>
                {loadedData.map((item) => {
                  if (item.category === "Liquide Middelen") {
                    if ((item.side === "Debet") && (+item.balance.toFixed(2) !== 0)) {
                      totalLiquideMiddelen += (+item.balance.toFixed(2));
                      cssClass = classes.debt;
                      return (
                        <div className={cssClass} key={item._id}>
                          <div className={classes.title}>{item.name}</div>
                          <div className={classes.value}>{(+item.balance.toFixed(2))}</div>
                        </div>
                      )
                    }
                    else {
                      return (null)
                    }
                  }
                  else {
                    return (null)
                  }
                })}
                <div className={classes.debt} key="totalLiquideId">
                  <div className={classes.boldTitle}>Totaal Liquide Middelen</div>
                  <div className={classes.boldValue}>{(+totalLiquideMiddelen.toFixed(2))}</div>
                </div>

              </div>
              <div className={classes.balanceTableCredit}>
                <h3>Eigen Vermogen</h3>
                {loadedData.sort( (b, a) => a.name.localeCompare(b.name) ).map((item) => {
                  // Calculate Total
                  if ((item.side === "Credit") && (+item.balance.toFixed(2) !== 0) && (item.name !== "Winst")) {
                    totalCredit += item.balance;
                    cssClass = classes.credit;
                  }
                  // Eigen Vermogen
                  if (item.category === "Eigen Vermogen") {
                    if ((item.side === "Credit") && (+item.balance.toFixed(2) !== 0)) {
                      totalEV += (+item.balance.toFixed(2));
                      cssClass = classes.credit;
                      return (
                        <div className={cssClass} key={item._id}>
                          <div className={classes.title}>{item.name}</div>
                          <div className={classes.value}>{(+item.balance.toFixed(2))}</div>
                        </div>
                      )
                    }
                    else {
                      return (null)
                    }
                  }
                  else {
                    return (null)
                  }
                })}
                <div className={classes.credit} key="totalEVId">
                  <div className={classes.boldTitle}>Totaal Eigen Vermogen</div>
                  <div className={classes.boldValue}>{+(totalEV.toFixed(2))}</div>
                </div>

                <h3>Kort Vreemd Vermogen</h3>
                {loadedData.map((item) => {
                  if (item.category === "Kort Vreemd Vermogen") {
                    if ((item.side === "Credit") && (+item.balance.toFixed(2) !== 0)) {
                      totalKVV += (+item.balance.toFixed(2));
                      cssClass = classes.credit;
                      return (
                        <div className={cssClass} key={item._id}>
                          <div className={classes.title}>{item.name}</div>
                          <div className={classes.value}>{(+item.balance.toFixed(2))}</div>
                        </div>
                      )
                    }
                    else {
                      return (null)
                    }
                  }
                  else {
                    return (null)
                  }
                })}
                <div className={classes.credit} key="totalKVVId">
                  <div className={classes.boldTitle}>Totaal Kort Vreemd Vermogen</div>
                  <div className={classes.boldValue}>{+totalKVV.toFixed(2)}</div>
                </div>

                {/* Add Profit  */}
                <h3>Winst</h3>
                {loadedProfit.map((item) => {
                  // Add Profit to total 
                  totalCredit += +item.balance.toFixed(2);
                  // Display Profit 
                  return (
                    <div className={cssClass} key={"winst"}>
                      <div className={classes.boldTitle}>{item.name}</div>
                      <div className={classes.boldValue}>{+item.balance.toFixed(2)}</div>
                    </div>
                  )
                })}
              </div>

            </div>
            {/* Add Totals */}
            <div className={classes.balanceTable}>
              <div className={classes.balanceTableDebt}>
                <div className={classes.debt} key="totalDebtId">
                  <div className={classes.boldTitle}>Totaal</div>
                  <div className={classes.boldValue}>{+totalDebt.toFixed(2)}</div>
                </div>
              </div>
              <div className={classes.balanceTableCredit}>
                <div className={classes.credit} key="totalCreditId">
                  <div className={classes.boldTitle}>Totaal</div>
                  <div className={classes.boldValue}>{+totalCredit.toFixed(2)}</div>
                </div>
              </div>
            </div>

          </BookItem>
        </div>
      </ul>
    </div>
  )
}