import { useRef } from "react";

import Card from "../ui/Card";
import classes from "./NewTaxrateForm.module.css";

export default function ChangeTaxrateForm(props) {
    const nameInputRef = useRef();
    const taxRateInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        const enteredName = nameInputRef.current.value;
        const enteredTaxRate = taxRateInputRef.current.value;
        const taxData = {
            name: enteredName,
            tax: enteredTaxRate
        };
        props.onStoreChangedTaxrate(taxData);
    }

    return <Card className={classes.card}>
        <form className={classes.card} onSubmit={submitHandler}>
            <div className={classes.card}>
                <label htmlFor='title'>New Name</label>
                <input type='text' required id='name' defaultValue={props.taxName} ref={nameInputRef} />
            </div>
            <div className={classes.card}>
                <label htmlFor='title'>New Taxrate</label>
                <input type='number' required id='tax' defaultValue={props.taxTax} ref={taxRateInputRef} />
            </div>
            <div className={classes.card}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}