import Card from "../ui/Card";
import classes from './BookItem.module.css';

function BookItem(props) {
    // console.log(props);
    if (props.className) {
        return (
            <li className={props.className} key={props.children.key}>
              
                <Card className={props.className}>
                    {props.children}
                </Card>
            </li>
        )
    }
    return (
        <li className={classes.item} key={props.children.key}>
          
            <Card>
                {props.children}
            </Card>
        </li>
    )
    
}

export default BookItem;
