import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './Taxrate.module.css';
import ChangeTaxrateForm from '../components/Bookings/ChangeTaxrateForm';
import Card from '../components/ui/Card';
import DisplayField from '../components/ui/DisplayField';
import GetAPI from '../api/GetAPI';
import PutAPI from '../api/PutAPI';

export default function ChangeTaxratePage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'taxrate';
    const params = useParams();
    const targetId = params.taxId;
    console.log("taxId: ", params)

    async function getData(targetId) {
        setIsLoading(true);
        await GetAPI(PAGE_URL + '/' + targetId)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData(targetId)
    }, [PAGE_URL, targetId])

    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }

    async function storeChangedTaxrateHandler(taxData) {
        const fullNewData = {
            id: targetId,
            name: taxData.name,
            tax: taxData.tax
        }
        console.log("sending: " + JSON.stringify(fullNewData));
        await PutAPI(PAGE_URL, fullNewData)
            .then((result) => {
                navigate("/taxrate");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.tax}>
            <h1>Change Taxrate</h1>
            <Card className={classes.card}>
                <h1>Current values</h1>
                <DisplayField type='text' label='Id' value={loadedData._id} readOnly={true} />
                <DisplayField type='text' label='Name' value={loadedData.name} readOnly={true} />
                <DisplayField type='text' label='Taxrate' value={loadedData.tax} readOnly={true} />
            </Card>
            <ChangeTaxrateForm onStoreChangedTaxrate={storeChangedTaxrateHandler} taxName={loadedData.name} taxTax={loadedData.tax} />
        </div>
    );
}