import { useState, useEffect } from 'react';
import classes from './Tax.module.css';
import BookItem from '../components/Bookings/BookItem';
import nlDate from '../helpers/ConvertFunction';
import GetAPI from '../api/GetAPI';
import YearSelect from '../helpers/YearSelector';
// import QuarterSelect from '../helpers/QuarterSelector';

export default function BTW() {
    const [isLoading, setIsLoading] = useState(true);
    const [loadedTaxPaid, setLoadedTaxPaid] = useState([]);
    const [loadedTaxRecieved, setLoadedTaxRecieved] = useState([]);
    // const [selectedYear, setSelectedYear] = useState(2023);
    const [selectedQuarter, setQuarter] = useState(1);
    const [selectedYear, setSelectedYear] = useState(YearSelect());
    // const [selectedQuarter, setQuarter] = useState(QuarterSelect());

    let totalPaid = 0;
    let totalRecieved = 0;
    const PAGE_URL = 'tax';

    // useEffect(() => {
    //     setIsLoading(true);
    //     GetAPI('tax/pay/' + YearSelect() + "/" + QuarterSelect())
    //     .then((data) => {
    //         setLoadedTaxPaid(data);
    //     })
    //     GetAPI('tax/recieve/' + YearSelect() + "/" + QuarterSelect())
    //         .then((data) => {
    //             setLoadedTaxRecieved(data);
    //         })
    //     setIsLoading(false);
    // }, [])

    // useEffect(() => {
    //     GetAPI('tax/pay/' + selectedYear + "/" + selectedQuarter)
    //     .then((data) => {
    //         setLoadedTaxPaid(data);
    //     })
    //     GetAPI('tax/recieve/' + selectedYear + "/" + selectedQuarter)
    //         .then((data) => {
    //             setLoadedTaxRecieved(data);
    //         })
    // }, [selectedYear, selectedQuarter])


    async function getTaxPaidData(PAGE_URL, selectedYear, selectedQuarter) {
        await GetAPI(PAGE_URL + '/pay/' + selectedYear + "/" + selectedQuarter)
            .then((data) => {
                setLoadedTaxPaid(data);
            })
    }

    async function getTaxRecievedData(PAGE_URL, selectedYear, selectedQuarter) {
        setIsLoading(true);
        await GetAPI(PAGE_URL + '/recieve/' + selectedYear + "/" + selectedQuarter)
            .then((data) => {
                setLoadedTaxRecieved(data);
            })
        setIsLoading(false);
    }

    useEffect(() => {
        setIsLoading(true);
        getTaxPaidData(PAGE_URL, selectedYear, selectedQuarter);
        getTaxRecievedData(PAGE_URL, selectedYear, selectedQuarter);
        setIsLoading(false);
    }, [PAGE_URL, selectedQuarter, selectedYear])

    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }

    function previousYearHandler(event) {
        event.preventDefault();
        setSelectedYear(selectedYear - 1)
    }

    function nextYearHandler(event) {
        event.preventDefault();
        setSelectedYear(selectedYear + 1)
    }

    function quarterHandler(event) {
        event.preventDefault();
        setQuarter(event.target.value);
        console.log(event.target.value);
    }

    return (
        <div className={classes.tax}>
            <div className={classes.control}>
                <label htmlFor='title'>Year</label>
                <input type='text' required id='year' value={selectedYear} readOnly={true} />
                <label htmlFor='title'>Quarter</label>
                <input type='text' required id='quarter' value={selectedQuarter} readOnly={true} />
            </div>

            <div className={classes.controlTable} >
                <button className={classes.navBtn} onClick={previousYearHandler}>Previous Year</button>
                <button className={classes.navBtn} value={1} onClick={quarterHandler}>Q1</button>
                <button className={classes.navBtn} value={2} onClick={quarterHandler}>Q2</button>
                <button className={classes.navBtn} value={3} onClick={quarterHandler}>Q3</button>
                <button className={classes.navBtn} value={4} onClick={quarterHandler}>Q4</button>
                <button className={classes.navBtn} onClick={nextYearHandler}>Next Year</button>
            </div>

            <h1>Tax Report</h1>
            <ul>
                <div className={classes.subHeader} >
                    Betaalde Btw
                </div>
                <BookItem key='taxPaidTitleRow' className={classes.card}>
                    <div className={classes.titlerow}>
                        <div className={classes.col2}>bookingNumber</div>
                        <div className={classes.col2}>bookingDate</div>
                        <div className={classes.col3}>relation</div>
                        <div className={classes.col4}>product</div>
                        <div className={classes.col1}>price</div>
                        <div className={classes.col1}>taxRate</div>
                        <div className={classes.col1}>taxAmount</div>
                        <div className={classes.col1}>total</div>
                        <div className={classes.col3}>firstPart</div>
                        <div className={classes.col2}>doFirstPart</div>
                        <div className={classes.col3}>secondPart</div>
                        <div className={classes.col2}>doSecondPart</div>
                        <div className={classes.col1}>doTax</div>
                        <div className={classes.col4}>reference</div>
                        <div className={classes.col0}></div>
                    </div>
                </BookItem>
                {loadedTaxPaid.map((item) => {
                    const nlBookingDate = nlDate(item.bookingDate);
                    totalPaid += item.taxAmount
                    return (
                        <BookItem className={classes.card} key={item._id}>
                            <div className={classes.bookingtable} key={item._id} >
                                <div className={classes.col2}>{item.bookingNumber}</div>
                                <div className={classes.col2}>{nlBookingDate}</div>
                                <div className={classes.col3}>{item.relation.name}</div>
                                <div className={classes.col4}>{item.product.name}</div>
                                <div className={classes.col1}>{item.price}</div>
                                <div className={classes.col1}>{item.taxRate.name}</div>
                                <div className={classes.col1}>{item.taxAmount}</div>
                                <div className={classes.col1}>{item.total}</div>
                                <div className={classes.col3}>{item.firstPart.name}</div>
                                <div className={classes.col2}>{item.doFirstPart}</div>
                                <div className={classes.col3}>{item.secondPart.name}</div>
                                <div className={classes.col2}>{item.doSecondPart}</div>
                                <div className={classes.col1}>{item.doTax}</div>
                                <div className={classes.col4}>{item.reference}</div>
                            </div>
                        </BookItem>
                    )
                })}
                <BookItem className={classes.card} key="totalPaid">
                    <div className={classes.bookingtable} key="paidTable" >
                        <div className={classes.col1Total}>Total Paid</div>
                        <div className={classes.col2}></div>
                        <div className={classes.col3}></div>
                        <div className={classes.col4}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col3Total}>{Math.ceil(totalPaid)}</div>
                        <div className={classes.col1}></div>
                        <div className={classes.col3}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col3}></div>
                        <div className={classes.col2}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col4}></div>
                        <div className={classes.col0}></div>
                    </div>
                </BookItem>
            </ul>
            <ul>
                <div className={classes.subHeader} >
                    Ontvangen Btw
                </div>
                <BookItem key='taxRecievedTitleRow' className={classes.card}>
                    <div className={classes.titlerow}>
                        <div className={classes.col2}>bookingNumber</div>
                        <div className={classes.col2}>bookingDate</div>
                        <div className={classes.col3}>relation</div>
                        <div className={classes.col4}>product</div>
                        <div className={classes.col1}>price</div>
                        <div className={classes.col1}>taxRate</div>
                        <div className={classes.col1}>taxAmount</div>
                        <div className={classes.col1}>total</div>
                        <div className={classes.col3}>firstPart</div>
                        <div className={classes.col2}>doFirstPart</div>
                        <div className={classes.col3}>secondPart</div>
                        <div className={classes.col2}>doSecondPart</div>
                        <div className={classes.col1}>doTax</div>
                        <div className={classes.col4}>reference</div>
                        <div className={classes.col0}></div>
                    </div>
                </BookItem>
                {loadedTaxRecieved.map((item) => {
                    const nlBookingDate = nlDate(item.bookingDate);
                    totalRecieved += item.taxAmount
                    return (
                        <BookItem className={classes.card} key={item._id}>
                            <div className={classes.bookingtable} key={item._id} >
                                <div className={classes.col2}>{item.bookingNumber}</div>
                                <div className={classes.col2}>{nlBookingDate}</div>
                                <div className={classes.col3}>{item.relation.name}</div>
                                <div className={classes.col4}>{item.product.name}</div>
                                <div className={classes.col1}>{item.price}</div>
                                <div className={classes.col1}>{item.taxRate.name}</div>
                                <div className={classes.col1}>{item.taxAmount}</div>
                                <div className={classes.col1}>{item.total}</div>
                                <div className={classes.col3}>{item.firstPart.name}</div>
                                <div className={classes.col2}>{item.doFirstPart}</div>
                                <div className={classes.col3}>{item.secondPart.name}</div>
                                <div className={classes.col2}>{item.doSecondPart}</div>
                                <div className={classes.col1}>{item.doTax}</div>
                                <div className={classes.col4}>{item.reference}</div>
                            </div>
                        </BookItem>
                    )
                })}
                <BookItem className={classes.card} key="totalRecieved">
                    <div className={classes.bookingtable} key="recievedTable" >
                        <div className={classes.col1Total}>Total Recieved</div>
                        <div className={classes.col2}></div>
                        <div className={classes.col3}></div>
                        <div className={classes.col3}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col3Total}>{Math.floor(totalRecieved)}</div>
                        <div className={classes.col1}></div>
                        <div className={classes.col3}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col3}></div>
                        <div className={classes.col2}></div>
                        <div className={classes.col1}></div>
                        <div className={classes.col4}></div>
                        <div className={classes.col0}></div>
                    </div>
                </BookItem>

            </ul>
            <ul>
                <div className={classes.subHeader} >
                    Btw te betalen of ontvangen
                </div>
                <div className={classes.bookingtable} >
                    <BookItem key='titleRow' className={classes.card}>
                        <div className={classes.titlerow}>
                            <div className={classes.title}>Te ontvangen btw</div>
                            <div className={classes.title}>Te betalen btw</div>
                        </div>
                        <div className={classes.balanceTable}>
                            {totalPaid > totalRecieved ?
                                <div className={classes.balanceTableDebt}>
                                    <div className={classes.debt} key="teontvangenbtw">
                                        <div className={classes.col3Total}>Bedrag te ontvangen</div>
                                        <div className={classes.col1Total}>{Math.ceil(totalPaid - totalRecieved)}</div>
                                    </div>
                                </div>
                                :
                                <div className={classes.balanceTableDebt}>
                                    <div className={classes.debt} key="teontvangenbtw">
                                        <div className={classes.title}></div>
                                        <div className={classes.value}></div>
                                    </div>
                                </div>
                            }
                            {totalPaid < totalRecieved ?
                                <div className={classes.balanceTableCredit}>
                                    <div className={classes.credit} key="tebetalenbtw">
                                        <div className={classes.col3Total}>Bedrag te betalen</div>
                                        <div className={classes.col1Total}>{Math.floor(Math.floor(totalRecieved) - Math.ceil(totalPaid))}</div>
                                    </div>
                                </div>
                                :
                                <div className={classes.balanceTableCredit}>
                                    <div className={classes.credit} key="tebetalenbtw">
                                        <div className={classes.title}></div>
                                        <div className={classes.value}></div>
                                    </div>
                                </div>
                            }
                        </div>
                    </BookItem>
                </div>
            </ul >
        </div >
    )
}