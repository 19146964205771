import { useState } from "react";
import classes from './Layout.module.css';
import { NavLink } from 'react-router-dom';
import logo from './images/logo.png';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignInButton } from "./SignInButton";
import { SignOutButton } from "./SignOutButton";

function Layout(props) {
    const isAuthenticated = useIsAuthenticated();
    const [navMenu, setNavMenu] = useState(false);

    const toggleNav = () => {
        setNavMenu(!navMenu);
    }

    return (
        <header className={classes.header}>
            <div>
                {/* <img src={logo} className={classes.logo} alt="logo" /> */}
                <NavLink to='/' ><img src={logo} className={classes.logo} alt="logo" /></NavLink>
                <main>
                    {isAuthenticated ?
                        <div>
                            <button className={classes.menubutton} onClick={() => toggleNav()} >Menu</button>
                            {navMenu ?
                                <ul className={classes.openmenu}>
                                    {props.children.props.children.map(data => <li key={data.props.element.type.name} >
                                        <NavLink className={(navData) => navData.isActive ? classes.active : classes.notactive} to={data.props.path} onClick={() => setNavMenu(false)}>{data.props.element.type.name}</NavLink>
                                    </li>
                                    )}
                                </ul>
                                :
                                <ul className={classes.closedmenu}>
                                    {props.children.props.children.map(data => <li key={data.props.element.type.name} >
                                        <NavLink className={(navData) => navData.isActive ? classes.active : classes.notactive} to={data.props.path}>{data.props.element.type.name}</NavLink>
                                    </li>
                                    )}
                                </ul>
                            }
                            <SignOutButton />
                        </div>

                        : <SignInButton />}
                </main>
            </div>
        </header>
    )
}

export default Layout;