import classes from './Card.module.css';

function Card(props) {
    // console.log(props);
    // return <div className={classes.card}>{props.children}</div>

    // return <div className={props.className}>{props.children}</div>
    if (props.className) {
        return <div className={props.className}>{props.children}</div>
    } else {
        return <div className={classes.card}>{props.children}</div>
    }
}

export default Card;