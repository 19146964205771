import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './BookingTable.module.css';
import ExternalDeleteHandler from '../components/DeleteHandler';
import nlDate from '../helpers/ConvertFunction';
import GetAPI from '../api/GetAPI';

export default function BookingTable() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'booking';

    async function getData() {
        setIsLoading(true);
        await GetAPI(PAGE_URL)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [PAGE_URL])

    if (isLoading) {
        return (
            <section>
                <p>{PAGE_URL}</p>
                <p>Loading....</p>
            </section>
        );
    }

    function newHandler(event) {
        event.preventDefault();
        navigate("/newbooking");
    }

    // function changeHandler(event) {
    //     event.preventDefault();
    //     console.log(event.target.value);
    //     const pLoad = JSON.parse(event.target.value)
    //     navigate("/changebooking/" + pLoad.id);
    // }

    // function passDeleteHandler(event) {
    //     ExternalDeleteHandler(event)
    //         .then((result) => {
    //             console.log("output: " + result);
    //             navigate(0);
    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         });
    // }

    return (
        <div className={classes.booking}>
            <h1>All Bookings</h1>
            <ul>
                <div className={classes.bookingtable} >
                    <button className={classes.navBtn} onClick={newHandler}>Create New Booking</button>
                </div>
                <table className={classes.table}>
                    <thead className={classes.tHead}>
                        <tr key='titleRow' className={classes.titleRow}>
                            <th className={classes.col05}>Boeking</th>
                            <th className={classes.col06}>Datum</th>
                            <th className={classes.col2}>Relatie</th>
                            <th className={classes.col2}>Product</th>
                            <th className={classes.col05}>Prijs</th>
                            <th className={classes.col05}>Btw</th>
                            <th className={classes.col05}>BtwBedrag</th>
                            <th className={classes.col05}>Totaal</th>
                            <th className={classes.col1}>firstPart</th>
                            <th className={classes.col06}>doFirstPart</th>
                            <th className={classes.col1}>secondPart</th>
                            <th className={classes.col06}>doSecondPart</th>
                            <th className={classes.col05}>doTax</th>
                            <th className={classes.col4}>reference</th>
                        </tr>
                    </thead>
                    <tbody className={classes.tBody}>
                        {loadedData.map((item) => {
                            // console.log(item)
                            const nlBookingDate = nlDate(item.bookingDate);
                            return (
                                <tr className={classes.bookingtable} key={item._id}>
                                    <td className={classes.col05}>{item.bookingNumber}</td>
                                    <td className={classes.col06}>{nlBookingDate}</td>
                                    <td className={classes.col2}>{item.relation.name}</td>
                                    <td className={classes.col2}>{item.product.name}</td>
                                    <td className={classes.col05}>{item.price}</td>
                                    <td className={classes.col05}>{item.taxRate.name}</td>
                                    <td className={classes.col05}>{item.taxAmount}</td>
                                    <td className={classes.col05}>{item.total}</td>
                                    <td className={classes.col1}>{item.firstPart.name}</td>
                                    <td className={classes.col06}>{item.doFirstPart}</td>
                                    <td className={classes.col1}>{item.secondPart.name}</td>
                                    <td className={classes.col06}>{item.doSecondPart}</td>
                                    <td className={classes.col05}>{item.doTax}</td>
                                    <td className={classes.col4}>{item.reference}</td>
                                    {/* <div className={classes.actions}>
                                                <button className={classes.btn} onClick={changeHandler} value={JSON.stringify({
                                                    id: item._id,
                                                    bookingNumber: item.bookingNumber,
                                                    bookingDate: item.bookingDate,
                                                    relation: item.relation.name,
                                                    product: item.product.name,
                                                    price: item.price,
                                                    taxRate: item.taxRate.name,
                                                    taxAmount: item.taxAmount,
                                                    total: item.total,
                                                    firstPart: item.firstPart,
                                                    doFirstPart: item.doFirstPart,
                                                    secondPart: item.secondPart,
                                                    doSecondPart: item.doSecondPart,
                                                    doTax: item.doTax,
                                                    reference: item.reference,
                                                })}>Change</button>
                                                <button className={classes.btn} onClick={passDeleteHandler} value={JSON.stringify({ id: item._id, path: "booking" })}>Delete</button>
                                            </div> */}
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </ul>
        </div>
    );
}