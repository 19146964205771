import { useRef } from "react";

import Card from "../ui/Card";
import classes from "./NewRelationForm.module.css";

export default function ChangeRelationForm(props) {
    const nameInputRef = useRef();
    const addressInputRef = useRef();

    function submitHandler(event) {
        event.preventDefault();
        const enteredName = nameInputRef.current.value;
        const enteredAddress = addressInputRef.current.value;
        const bodyData = {
            name: enteredName,
            address: enteredAddress
        };
        props.onStoreChangedRelation(bodyData);
    }

    return <Card className={classes.card}>
        <form className={classes.card} onSubmit={submitHandler}>
            <div className={classes.card}>
                <label htmlFor='title'>New Name</label>
                <input type='text' required id='name' defaultValue={props.relationName} ref={nameInputRef} />
            </div>
            <div className={classes.card}>
                <label htmlFor='title'>New Address</label>
                <input type='text' required id='address' defaultValue={props.relationAddress} ref={addressInputRef} />
            </div>
            <div className={classes.card}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}

