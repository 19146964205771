import { useNavigate } from 'react-router-dom';
import classes from './Relation.module.css';
import NewBookingForm from '../components/Bookings/NewBookingForm';
import PostAPI from '../api/PostAPI';

export default function NewBookingPage() {
    const navigate = useNavigate();
    const PAGE_URL = 'booking';
    async function storeNewHandler(newData) {
        await PostAPI(PAGE_URL, newData)
            .then(() => {
                navigate("/booking");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.relation}>
            <h1>Create New Booking</h1>
            <NewBookingForm onStoreNew={storeNewHandler} />
        </div>
    );
}