import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './Booking.module.css';
import ExternalDeleteHandler from '../components/DeleteHandler';
import ApiUrl from '../ApiUrl';
import nlDate from '../helpers/ConvertFunction';
import GetAPI from '../api/GetAPI';

export default function Boekingen() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const API_URL = ApiUrl();
    const PAGE_URL = 'booking';

    async function getData() {
        setIsLoading(true);
        await GetAPI('booking')
            .then((data) => {
                console.log("bookingData: " + JSON.stringify(data));
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData()
    }, [PAGE_URL])

    if (isLoading) {
        return (
            <section>
                <p>{API_URL} + 'Booking'</p>
                <p>Loading....</p>
            </section>
        );
    }

    function newHandler(event) {
        event.preventDefault();
        navigate("/newbooking");
    }

    function changeHandler(event) {
        event.preventDefault();
        const pLoad = JSON.parse(event.target.value)
        navigate("/changebooking/" + pLoad.id);
    }

    function passDeleteHandler(event) {
        ExternalDeleteHandler(event)
            .then((result) => {
                navigate(0);
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.booking}>
            <h1>All Bookings</h1>
            <ul>
                <div className={classes.bookingtables} >
                    <button className={classes.navBtn} onClick={newHandler}>Create New Booking</button>
                </div>
                <table className={classes.table}>
                    <thead className={classes.tHead}>
                        <tr className={classes.titlerow}>
                            <th className={classes.col2}>Boeking</th>
                            <th className={classes.col3}>Date</th>
                            <th className={classes.col5}>Relatie</th>
                            <th className={classes.col5}>Product</th>
                            <th className={classes.coleuro}>Prijs</th>
                            <th className={classes.col3}>Btw</th>
                            <th className={classes.coleuro}>BtwBedrag</th>
                            <th className={classes.coleuro}>Totaal</th>
                            <th className={classes.col5}>firstPart</th>
                            <th className={classes.col4}>doFirstPart</th>
                            <th className={classes.col5}>secondPart</th>
                            <th className={classes.col4}>doSecondPart</th>
                            <th className={classes.col3}>doTax</th>
                            <th className={classes.col6}>reference</th>
                            <th className={classes.actions}></th>
                        </tr>
                    </thead>
                    <tbody className={classes.tBody}>
                        {loadedData.map((item) => {
                            const nlBookingDate = nlDate(item.bookingDate);
                            return (
                                <tr className={classes.bookingrows} key={item._id}>

                                    <td className={classes.col2}>{item.bookingNumber}</td>
                                    <td className={classes.col3}>{nlBookingDate}</td>
                                    <td className={classes.col5}>{item.relation.name}</td>
                                    <td className={classes.col5}>{item.product.name}</td>
                                    <td className={classes.coleuro}>{item.price}</td>
                                    <td className={classes.col3}>{item.taxRate.name}</td>
                                    <td className={classes.coleuro}>{item.taxAmount}</td>
                                    <td className={classes.coleuro}>{item.total}</td>
                                    <td className={classes.col5}>{item.firstPart.name}</td>
                                    <td className={classes.col4}>{item.doFirstPart}</td>
                                    <td className={classes.col5}>{item.secondPart.name}</td>
                                    <td className={classes.col4}>{item.doSecondPart}</td>
                                    <td className={classes.col3}>{item.doTax}</td>
                                    <td className={classes.col6}>{item.reference}</td>
                                    <td className={classes.actions}>
                                        <button className={classes.btn} onClick={changeHandler} value={JSON.stringify({
                                            id: item._id,
                                            bookingNumber: item.bookingNumber,
                                            bookingDate: item.bookingDate,
                                            relation: item.relation.name,
                                            product: item.product.name,
                                            price: item.price,
                                            taxRate: item.taxRate.name,
                                            taxAmount: item.taxAmount,
                                            total: item.total,
                                            firstPart: item.firstPart,
                                            doFirstPart: item.doFirstPart,
                                            secondPart: item.secondPart,
                                            doSecondPart: item.doSecondPart,
                                            doTax: item.doTax,
                                            reference: item.reference,
                                        })}>Change</button>
                                        <button className={classes.btn} onClick={passDeleteHandler} value={JSON.stringify({ id: item._id, path: "booking" })}>Delete</button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </ul>
        </div>
    );
}