import { useRef, useState, useEffect } from "react";
import Card from "../ui/Card";
import classes from "./NewProductForm.module.css";
import ApiUrl from '../../ApiUrl';
import GetAPI from "../../api/GetAPI";

export default function NewProductForm(props) {
    const nameInputRef = useRef();
    const articleNumberInputRef = useRef();
    const [selectedRelation, setRelation] = useState('');
    const [loadedRelations, setLoadedRelations] = useState([]);
    const [selectedTaxRate, setTaxRate] = useState('');
    const [loadedTaxRate, setLoadedTaxRate] = useState([]);
    const [selectedFirstChoice, setFirstChoice] = useState();
    const [selectedFirstPart, setFirstPart] = useState();
    const [loadedAsset, setLoadedAsset] = useState([]);
    const [selectedDoFirstPart, setDoFirstPart] = useState();
    const [selectedSecondChoice, setSecondChoice] = useState();
    const [selectedSecondPart, setSecondPart] = useState();
    const [selectedDoSecondPart, setDoSecondPart] = useState();
    const [selectedDoTax, setDoTax] = useState();
    const referenceInputRef = useRef();
    const API_URL = ApiUrl();


    async function getRelation() {
        await GetAPI('relation')
            .then((data) => {
                setLoadedRelations(data);
            })
    }

    async function getTaxRate() {
        await GetAPI('taxrate')
            .then((data) => {
                setLoadedTaxRate(data);
            })
    }

    async function getAsset() {
        await GetAPI('asset')
            .then((data) => {
                setLoadedAsset(data);
            })
    }

    useEffect(() => {
        getRelation()
        getTaxRate()
        getAsset()
    }, [API_URL])

    function submitHandler(event) {
        event.preventDefault();
        const enteredName = nameInputRef.current.value;
        const enteredArticleNumber = articleNumberInputRef.current.value;
        const enteredReference = referenceInputRef.current.value;
        const bodyData = {
            name: enteredName,
            relation: selectedRelation,
            articleNumber: enteredArticleNumber,
            taxRate: selectedTaxRate,
            firstChoice: selectedFirstChoice,
            firstPart: selectedFirstPart,
            doFirstPart: selectedDoFirstPart,
            secondChoice: selectedSecondChoice,
            secondPart: selectedSecondPart,
            doSecondPart: selectedDoSecondPart,
            doTax: selectedDoTax,
            reference: enteredReference
        };
        props.onStoreNewRecord(bodyData);
    }

    return <Card className={classes.card}>
        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>

                <label htmlFor='title'>Name</label>
                <input type='text' required id='name' ref={nameInputRef} />
            </div>
            <div>
                <label htmlFor='title'>Relation</label>
                <select value={selectedRelation} required id='relation' onChange={e => setRelation(e.target.value)}>
                    <option key="none" value=""></option>
                    {loadedRelations.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>
            <div className={classes.control}>
                <label htmlFor='title'>ArticleNumber</label>
                <input type='text' id='articleNumber' ref={articleNumberInputRef} />
            </div>
            <div>
                <label htmlFor='title'>Tax</label>
                <select value={selectedTaxRate} required id='taxRate' onChange={e => setTaxRate(e.target.value)}>
                    <option key="none" value=""></option>
                    {loadedTaxRate.map((item) => {
                        return (
                            <option key={item._id} value={item._id}> {item.name}</option>
                        )
                    })}
                </select>
            </div>
            <div>
                <label htmlFor='title'>FirstChoice</label>
                <select value={selectedFirstChoice} required id='firstChoice' onChange={e => setFirstChoice(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>FirstPart</label>
                <select value={selectedFirstPart} required id='firstPart' onChange={e => setFirstPart(e.target.value)}>
                    <option key="none" value=""></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedFirstChoice) {
                            return (
                                <option key={item._id} value={item._id}> {item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoFirstPart} required id='doFirstPart' onChange={e => setDoFirstPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="add" value="add">Add</option>
                    <option key="substract" value="substract">Substract</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondChoice</label>
                <select value={selectedSecondChoice} required id='secondChoice' onChange={e => setSecondChoice(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>SecondPart</label>
                <select value={selectedSecondPart} required id='secondPart' onChange={e => setSecondPart(e.target.value)}>
                    <option key="none" value=""></option>
                    {loadedAsset.map((item) => {
                        if (item.type === selectedSecondChoice) {
                            return (
                                <option key={item._id} value={item._id}> {item.name}</option>
                            )
                        } else {
                            return null
                        }
                    })}
                </select>
            </div>

            <div>
                <label htmlFor='title'>Action</label>
                <select value={selectedDoSecondPart} required id='doSecondPart' onChange={e => setDoSecondPart(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="add" value="add">Add</option>
                    <option key="substract" value="substract">Substract</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>TaxAction</label>
                <select value={selectedDoTax} id='doTax' defaultValue="" onChange={e => setDoTax(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="pay" value="pay">Pay</option>
                    <option key="recieve" value="recieve">Recieve</option>
                </select>
            </div>

            <div>
                <label htmlFor='title'>Reference</label>
                <input type='text' id='reference' defaultValue="" ref={referenceInputRef} />
            </div>
            <div className={classes.action}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}