import DeleteAPI from "../api/DeleteAPI";

async function ExternalDeleteHandler(event, response) {
    const payLoad = JSON.parse(event.target.value);
    console.log(event.target.value);
    const idData = {
        id: payLoad.id
    };
    await DeleteAPI(payLoad.path, idData)
}

export default ExternalDeleteHandler;