import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import classes from './Taxrate.module.css';
import ChangeAssetForm from '../components/Bookings/ChangeAssetForm';
import Card from '../components/ui/Card';
import DisplayField from '../components/ui/DisplayField';
import GetAPI from '../api/GetAPI';
import PutAPI from '../api/PutAPI';

export default function ChangeAssetPage() {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [loadedData, setLoadedData] = useState([]);
    const PAGE_URL = 'asset';
    const params = useParams();
    const targetId = params.id;
    console.log("id: ", params)

    async function getData(targetId) {
        setIsLoading(true);
        await GetAPI(PAGE_URL + '/' + targetId)
            .then((data) => {
                setLoadedData(data);
                setIsLoading(false)
            })
    }

    useEffect(() => {
        getData(targetId)
    }, [PAGE_URL, targetId])


    if (isLoading) {
        return (
            <section>
                <p>Loading....</p>
            </section>
        );
    }

    async function storeChangedHandler(newData) {
        const fullNewData = {
            id: targetId,
            name: newData.name,
            type: newData.type,
            side: newData.side,
            category: newData.category
        }
        console.log("sending: " + JSON.stringify(fullNewData));
        await PutAPI(PAGE_URL, fullNewData)
            .then((result) => {
                navigate("/asset");
            })
            .catch((err) => {
                console.log(err)
            });
    }

    return (
        <div className={classes.tax}>
            <h1>Change Asset</h1>
            <Card className={classes.card}>
                <h1>Current values</h1>
                <DisplayField type='text' label='Id' value={loadedData._id} readOnly={true} />
                <DisplayField type='text' label='Name' value={loadedData.name} readOnly={true} />
                <DisplayField type='text' label='Type' value={loadedData.type} readOnly={true} />
                <DisplayField type='text' label='Side' value={loadedData.side} readOnly={true} />
                <DisplayField type='text' label='Category' value={loadedData.category} readOnly={true} />
            </Card>
            <ChangeAssetForm onStoreChanged={storeChangedHandler} assetName={loadedData.name} assetType={loadedData.type} assetSide={loadedData.side} assetCategory={loadedData.category} />
        </div>
    );
}