import { useRef, useState } from "react";

import Card from "../ui/Card";
import classes from "./NewTaxrateForm.module.css";

export default function NewAssetForm(props) {
    const nameInputRef = useRef();
    const [selectedType, setType] = useState([]);
    const [selectedSide, setSide] = useState([]);
    const [selectedCategory, setCategory] = useState([]);

    function submitHandler(event) {
        event.preventDefault();
        const enteredName = nameInputRef.current.value;
        const newData = {
            name: enteredName,
            type: selectedType,
            side: selectedSide,
            category: selectedCategory
        };
        props.onStoreNew(newData);
    }

    return <Card className={classes.card}>
        <form className={classes.form} onSubmit={submitHandler}>
            <div className={classes.control}>
                <label htmlFor='title'>Name</label>
                <input type='text' required id='name' ref={nameInputRef} />
            </div>
            <div>
                <label htmlFor='title'>Type</label>
                <select defaultValue="" required id='type' onChange={e => setType(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="resultatenrekening" value="Resultatenrekening">Resultatenrekening</option>
                    <option key="balans" value="Balans">Balans</option>
                </select>
            </div>
            <div>
                <label htmlFor='title'>Side</label>
                <select defaultValue="" required id='type' onChange={e => setSide(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="debet" value="Debet">Debet</option>
                    <option key="credit" value="Credit">Credit</option>
                </select>
            </div>
            <div>
                <label htmlFor='title'>category</label>
                <select defaultValue="" required id='type' onChange={e => setCategory(e.target.value)}>
                    <option key="none" value=""></option>
                    <option key="vasteActiva" value="Vaste Activa">Vaste Activa</option>
                    <option key="vlottendeActiva" value="Vlottende Activa">Vlottende Activa</option>
                    <option key="liquideMiddelen" value="Liquide Middelen">Liquide Middelen</option>
                    <option key="eigenVermogen" value="Eigen Vermogen">Eigen Vermogen</option>
                    <option key="kvv" value="Kort Vreemd Vermogen">Kort Vreemd Vermogen</option>
                    <option key="kosten" value="Kosten">Kosten</option>
                    <option key="opbrengsten" value="Opbrengsten">Opbrengsten</option>
                </select>
            </div>
            <div className={classes.action}>
                <button>Submit</button>
            </div>
        </form>
    </Card>
}